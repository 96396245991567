import { getConstant } from "./constants"
import {
  theme as defaultTheme,
  engine as defaultEngine,
  palette as defaultPalette
} from "./defaultTheme"
import {
  theme as unionsNSWTheme,
  engine as unionsNSWEngine,
  palette as unionsNSWPalette,
  extraStyles as unionsNSWStyles,
  sendJoinerToActionNetwork as unionsNSWEndpoint
} from "./customs/unionsnsw/theme"
import {
  theme as megaphoneTheme,
  engine as megaphoneEngine,
  palette as megaphonePalette
} from "./customs/megaphone/theme"
import {
  theme as qldTheme,
  engine as qldEngine,
  palette as qldPalette
} from "./customs/qld/theme"
import {
  theme as hunterTheme,
  engine as hunterEngine,
  palette as hunterPalette
} from "./customs/hunter/theme"
import {
  theme as saunionsTheme,
  engine as saunionsEngine,
  palette as saunionsPalette
} from "./customs/saunions/theme"
import {
  theme as actuTheme,
  engine as actuEngine,
  palette as actuPalette
} from "./customs/actu/theme"
import { Theme } from "baseui/theme"
import { Client } from "styletron-engine-atomic"
import { StyleObject } from "styletron-react"
import { FormValues as PersonalDetailsFormValues } from "./pages/PersonalDetailsForm"
interface Palette {
  white: string
  black: string
  primary: string
  secondary: string
  tertiary: string
}
interface ConfigurablesObject {
  theme: Theme
  engine: Client
  palette: Palette
  extraStyles?: {
    [key: string]: StyleObject
  }
  sendJoinerData?: (joiner: PersonalDetailsFormValues) => Promise<any>
}

let configurables: ConfigurablesObject = {
  theme: defaultTheme,
  engine: defaultEngine,
  palette: defaultPalette
}

if (getConstant("formVersion")?.includes("unionsnsw")) {
  configurables.theme = unionsNSWTheme
  configurables.engine = unionsNSWEngine
  configurables.palette = unionsNSWPalette
  configurables.extraStyles = unionsNSWStyles
  configurables.sendJoinerData = unionsNSWEndpoint
} else if (getConstant("formVersion")?.includes("megaphone")) {
  configurables.theme = megaphoneTheme
  configurables.engine = megaphoneEngine
  configurables.palette = megaphonePalette
} else if (getConstant("formVersion")?.includes("hunter")) {
  configurables.theme = hunterTheme
  configurables.engine = hunterEngine
  configurables.palette = hunterPalette
} else if (getConstant("formVersion")?.includes("qld")) {
  configurables.theme = qldTheme
  configurables.engine = qldEngine
  configurables.palette = qldPalette
} else if (getConstant("formVersion")?.includes("saunions")) {
  configurables.theme = saunionsTheme
  configurables.engine = saunionsEngine
  configurables.palette = saunionsPalette
} else if (getConstant("formVersion")?.includes("actu")) {
  configurables.theme = actuTheme
  configurables.engine = actuEngine
  configurables.palette = actuPalette
}

export const {
  theme,
  engine,
  palette,
  extraStyles,
  sendJoinerData
} = configurables
