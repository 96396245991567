import React, { useEffect } from "react"
import { useStyletron } from "baseui"
import { Grid, Cell } from "baseui/layout-grid"
import { ProgressBar } from "./Progress"
import { Checkbox } from "./Checkbox"
import { Person } from "../App"
import { Heading, HeadingLevel } from "baseui/heading"
import { useLocation, useHistory } from "react-router-dom"
import { findBranch } from "../util"
import { getConstant } from "../constants"
import { ParagraphMedium } from "baseui/typography"

export const Layout = ({
  children,
  heading,
  sectionStep,
  disableBackwardNavigation,
  person,
  setPerson,
  volunteerOnly,
  scrollTrigger
}: {
  children: React.ReactNode
  heading: string
  person: Person
  setPerson?: (person: Person | ((oldPerson: Person) => Person)) => void
  sectionStep: number
  disableBackwardNavigation?: boolean
  volunteerOnly?: boolean
  scrollTrigger?: number
}) => {
  const [css] = useStyletron()
  const progressRef = React.useRef()
  const { pathname } = useLocation()

  useEffect(() => {
    if (
      scrollTrigger &&
      progressRef &&
      progressRef.current &&
      ((progressRef.current as unknown) as HTMLElement).scrollIntoView
    ) {
      ;((progressRef.current as unknown) as HTMLElement).scrollIntoView(true)
    }
  }, [pathname, scrollTrigger])
  const branch = findBranch(person)
  const { push } = useHistory()
  let sections = [
    {
      label: "Personal details",
      url: "/"
    }
  ]
  if (person.join) {
    sections.push({
      label: "Work details",
      url: person.last_work_details_path || "/employment-details"
    })
    sections.push({
      label: "Your Union",
      url: "/union"
    })
    // TODO: if any additional fields are set this works, but means
    // Payment selects the wrong indexed orb in Layout due to the amount
    // of orbs change???
    if (pathname === "/union-additional-fields" || person.additional) {
      sections.push({
        label: "Additional Details",
        url: "/union-additional-fields"
      })
    }
    if (pathname === "/netsuite-form") {
      sections.push({
        label: "Netsuite Form",
        url: "/netsuite-form"
      })
    }
    if (
      (!branch || branch.payment_mode !== "bypass") &&
      pathname !== "/netsuite-form"
    ) {
      sections.push({
        label: "Payment options",
        url: "/payment"
      })
    }
  }
  if (person.volunteer) {
    sections.push({
      label: "Volunteer details",
      url: "/volunteer-details"
    })
  }
  sections.push({
    label: "Finish",
    url: "/complete"
  })
  // Only show the Share Page if we are at the end of the form
  if (
    pathname === "/complete" ||
    pathname === "/share" ||
    pathname === "/share-complete"
  ) {
    sections.push({
      label: "Share",
      url: "/share"
    })
  }
  // For coverage lookup, replace the whole sections list with a single page
  if (getConstant("coverageOnly")) {
    if (pathname !== "/coverage" && pathname !== "/manual-coverage") {
      push(`/coverage`)
    }
  }
  return (
    <HeadingLevel>
      <Grid gridMargins={[16, 24, 24]}>
        {sectionStep === 0 &&
          !volunteerOnly &&
          getConstant("volunteerEnabled") && (
            <Cell span={[4, 8, 12]}>
              <Checkbox
                name="join"
                onChange={e => {
                  if (setPerson) {
                    setPerson({ ...person, join: e.currentTarget.checked })
                  }
                }}
                checked={person.join}
              >
                I want to join my union
              </Checkbox>
              <Checkbox
                name="volunteer"
                onChange={e => {
                  if (setPerson) {
                    setPerson({ ...person, volunteer: e.currentTarget.checked })
                  }
                }}
                checked={person.volunteer}
              >
                I also want to volunteer on Australian Unions' campaigns
              </Checkbox>
            </Cell>
          )}
        {(person.join || person.volunteer) && (
          <>
            <Cell
              overrides={{ Cell: { props: { ref: progressRef } } }}
              span={[4, 8, 12]}
            >
              <ProgressBar
                sections={sections}
                sectionStep={sectionStep}
                disableBackwardNavigation={disableBackwardNavigation}
              />
            </Cell>
          </>
        )}
        <>
          <Cell span={[4, 8, 12]}>
            <HeadingLevel>
              <Heading
                overrides={{
                  Block: {
                    style: ({ $theme }) => ({
                      ...$theme.typography.HeadingXLarge,
                      marginTop: "16px",
                      marginBottom: "0",
                      marginLeft: "0s",
                      marginRight: "0"
                    })
                  }
                }}
              >
                {heading}
              </Heading>
              <hr
                className={css({
                  border: 0,
                  height: "2px",
                  backgroundColor: "#333333"
                })}
              />
            </HeadingLevel>
          </Cell>
        </>
        {getConstant("coverageOnly") && (
          <Cell span={[4, 8, 12]}>
            <ParagraphMedium>
              This tool is designed to be used by ACTU affiliated unions and
              Trades and Labour Councils, it is not a functioning join form.
            </ParagraphMedium>
            <ParagraphMedium>
              The tool uses the same coverage lookup data source used by the{" "}
              <a href="https://join.australianunions.org.au">
                Australian Unions join form
              </a>
              . Coverage has been determined from a series of meetings between
              the ACTU, unions and TLCs.
            </ParagraphMedium>
            <ParagraphMedium>
              If you experience any issues using the tool, or have questions,
              you can contact the ACTU Technology Team - actudev@actu.org.au
            </ParagraphMedium>
          </Cell>
        )}
      </Grid>
      <Grid gridMargins={[16, 24, 24]}>{children}</Grid>
    </HeadingLevel>
  )
}
