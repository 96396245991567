import Analytics from "analytics"
import React from "react"
import { useLocation } from "react-router-dom"
import { Person } from "./App"
import { getStateByPostcode } from "./util"
import { getDeepestIndustry } from "./util"
import { getConstant } from "./constants"
const googleAnalytics = require("@analytics/google-analytics")
const segment = require("@analytics/segment")

interface FacebookPixelConfig {
  pixelId: string
  disablePushStateTracking?: boolean
}
//fbpixel plugin
function facebookPixelPlugin(userConfig: FacebookPixelConfig) {
  return {
    name: "fbpixel",
    config: {
      pixelId: userConfig.pixelId,
      disablePushStateTracking: userConfig.disablePushStateTracking
    },
    initialize: ({ config }: { config: FacebookPixelConfig }) => {
      /* eslint-disable */
      // prettier-ignore
      // @ts-ignore
      !function(f,b,e,v,n,t,s)
      // prettier-ignore
      // @ts-ignore
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      // prettier-ignore
      // @ts-ignore
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      // prettier-ignore
      // @ts-ignore
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      // prettier-ignore
      // @ts-ignore
      n.queue=[];t=b.createElement(e);t.async=!0;
      // prettier-ignore
      // @ts-ignore
      t.src=v;s=b.getElementsByTagName(e)[0];
      // prettier-ignore
      // @ts-ignore
      s.parentNode.insertBefore(t,s)}(window,document,'script',
      // prettier-ignore
      'https://connect.facebook.net/en_US/fbevents.js');
      /* eslint-enable */

      fbq("init", config.pixelId)
    },
    track: ({ payload }: any) => {
      fbq("track", payload.event, payload.properties)
    },
    identify: ({
      payload,
      config
    }: {
      payload: any
      config: FacebookPixelConfig
    }) => {
      const { userId, traits } = payload
      fbq("init", config.pixelId, {
        external_id: userId,
        ...traits
      })
    },
    loaded: () => {
      return !!window.fbq
    }
  }
}

/* Initialize analytics & load plugins */
const plugins = []
if (getConstant("googleAnalyticsId")) {
  plugins.push(
    googleAnalytics.default({
      measurementIds: [getConstant("googleAnalyticsId")],
      cookieConfig: {
        cookieName: "embedForm_gaCookie",
        cookieFlags: "SameSite=None; Secure"
      }
    })
  )
}
if (getConstant("segmentWriteKey")) {
  plugins.push(
    segment.default({
      writeKey: getConstant("segmentWriteKey")
    })
  )
}
if (process.env.REACT_APP_FB_PIXEL_ID) {
  plugins.push(
    facebookPixelPlugin({
      pixelId: process.env.REACT_APP_FB_PIXEL_ID
    })
  )
}
export const analytics = Analytics({
  app: "embeddable-join",
  plugins: plugins
})
const MILLISECONDS_IN_A_YEAR = 3.15576e10
//https://stackoverflow.com/a/50827764
function getAge(birthDate: Date) {
  const age = new Date().getTime() - new Date(birthDate).getTime()
  return Math.floor(age / MILLISECONDS_IN_A_YEAR)
}
function usePrevious(value: any): Person | undefined {
  const ref = React.useRef()
  React.useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export function AnalyticsTracker({ person }: { person: Person }) {
  const { pathname } = useLocation()
  const prevPerson = usePrevious(person)
  React.useEffect(() => {
    if (process.env.REACT_APP_ANALYTICS_IDENTIFY_ENABLED === "true") {
      const dob = person.date_of_birth && person.date_of_birth[0]
      const age = dob ? getAge(dob) : null
      const state = person.postcode ? getStateByPostcode(person.postcode) : null
      if (person.people_id && person.mobile) {
        analytics.identify(
          person.people_id,
          {
            name: person.full_name,
            email: person.email,
            phone: person.mobile,
            gender: person.gender,
            age: age,
            address: {
              city: person.suburb,
              postalCode: person.postcode,
              state: state,
              country: "Australia"
            },
            title:
              person.job_title ||
              (person.job_search && person.job_search.label),
            industry: getDeepestIndustry(
              person.industry_1,
              person.industry_2,
              person.industry_3,
              person.industry_4,
              person.job_search
            ),
            duties: person.duties,
            job_type: person.job_type,
            hours_per_week: person.hours_per_week,
            person_id: person.person_id,
            has_begun_join_form: person.join,
            has_begun_volunteer_form: person.volunteer
          },
          {
            plugins: {
              all: false,
              segment: true
            }
          }
        )
        const names = person.full_name && person.full_name.split(" ")
        let fn, ln
        if (names && names.length === 2) {
          fn = names[0].toLowerCase()
          ln = names[1].toLowerCase()
        } else if (names && names.length === 1) {
          fn = names[0].toLowerCase()
        }
        let gender
        if (person.gender === "m" || person.gender === "f") {
          gender = person.gender
        }
        let mobile
        if (person.mobile) {
          if (person.mobile[0] === "0") {
            mobile = "61" + person.mobile.slice(1)
          } else {
            mobile = person.mobile
          }
          mobile = mobile.replace(/\s/g, "")
        }
        analytics.identify(
          person.people_id,
          {
            external_id: person.mobile,
            em: person.email && person.email.toLowerCase(),
            fn: fn,
            ln: ln,
            ph: mobile,
            ge: gender,
            db: dob
              ? `${dob.getFullYear()}${dob.getMonth() + 1}${dob.getDate()}`
              : null,
            ct: person.suburb && person.suburb.toLowerCase().replace(/\s/g, ""),
            st: state && state.toLowerCase(),
            zp: person.postcode,
            cn: "au"
          },
          {
            plugins: {
              all: false,
              fbpixel: true
            }
          }
        )
      }
    }
    // track adding vol/join to cart
    if (person.join && !(prevPerson && prevPerson.join)) {
      analytics.track(
        "AddToCart",
        { content_name: "join" },
        {
          plugins: {
            all: false,
            fbpixel: true
          }
        }
      )
    }
    if (person.volunteer && !(prevPerson && prevPerson.volunteer)) {
      analytics.track(
        "AddToCart",
        { content_name: "volunteer" },
        {
          plugins: {
            all: false,
            fbpixel: true
          }
        }
      )
    }
  }, [person, prevPerson])

  React.useEffect(() => {
    analytics.page({ path: pathname })
  }, [pathname])

  return null
}
