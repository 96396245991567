import React from "react"
import ReactDOM from "react-dom"
import { HashRouter as Router } from "react-router-dom"
import App from "./App"
import * as Sentry from "@sentry/browser"
import { getConstant } from "./constants"
import ReactInspectlet from "./components/Inspectlet"

import { getQueryVariable, createFullName } from "./util"
if (process.env.NODE_ENV === "production" && getConstant("sentryURL")) {
  Sentry.init({
    dsn: getConstant("sentryURL"),
    integrations: Sentry.defaultIntegrations
  })
}

const inspectlet_code = getConstant("inspectletCode")
if (inspectlet_code) {
  ReactInspectlet.initialize(inspectlet_code)
}

const node = document.getElementById("join-root")
const utm_campaign = node && node.getAttribute("data-utm-campaign")
const utm_medium = node && node.getAttribute("data-utm-medium")
const utm_source = node && node.getAttribute("data-utm-source")
const utm_content = node && node.getAttribute("data-utm-content")
const source = node && node.getAttribute("data-source")
const referer = node && node.getAttribute("data-referer")
// completely disable volunteer path through form now that NB is offline
const volunteerOnly = false // !!(node && node.getAttribute("data-volunteer-only"))
const search = window.location.search
const full_name = createFullName(
  getQueryVariable("firstname", search) || null,
  getQueryVariable("lastname", search) || null
)
const email = getQueryVariable("email", search)
const mobile = getQueryVariable("mobile", search)

const coverageState = getQueryVariable("state", search)

if (node) {
  ReactDOM.render(
    <Router>
      <App
        volunteerOnly={volunteerOnly}
        coverageState={coverageState}
        utm_medium={utm_medium}
        utm_source={utm_source}
        utm_campaign={utm_campaign}
        utm_content={utm_content}
        source={source}
        referer={referer}
        full_name={full_name}
        email={email}
        mobile={mobile}
      />
    </Router>,
    node
  )
}
