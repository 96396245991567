import React from "react"
import { Layout } from "../components/Layout"
import { RunIdleTimer } from "../components/RunIdleTimer"
import { Cell } from "baseui/layout-grid"
import { Button } from "../components/Button"
import { useForm, Controller } from "react-hook-form"
import { FormControl } from "../components/FormControl"
import { useHistory } from "react-router-dom"
import { sendVolunteerSignup } from "../api"
import { Checkbox } from "../components/Checkbox"
import { Person } from "../App"
import { Notification } from "baseui/notification"
import { analytics } from "../analytics"
import { findBranch } from "../util"

interface VolunteerDetailsFormProps {
  person: Person
  setPerson: (person: Person | ((oldPerson: Person) => Person)) => void
  resetPersonalDetails: (completeReset?: boolean) => void
  volunteerOnly: boolean
}

export interface FormValues {
  campaignNews: boolean
  leafletsActionTalking: boolean
}

export const VolunteerDetailsForm = ({
  person,
  setPerson,
  resetPersonalDetails,
  volunteerOnly
}: VolunteerDetailsFormProps) => {
  const { handleSubmit, control, errors } = useForm<FormValues>({
    defaultValues: {
      leafletsActionTalking: person.leafletsActionTalking || false,
      campaignNews: person.campaignNews || false
    }
  })
  const [formError, setFormError] = React.useState("")
  const [isLoading, setIsLoading] = React.useState(false)
  const [scrollTrigger, setScrollTrigger] = React.useState(1)
  const topError =
    formError ||
    (Object.keys(errors).length > 0
      ? "Please fill in the required information below"
      : "")
  const { push } = useHistory()
  if (!(person && person.postcode)) {
    push("/")
  }

  const onSubmit = (values: FormValues) => {
    setFormError("")
    const actionError = !(values.leafletsActionTalking || values.campaignNews)
    if (actionError) {
      setFormError("You must pick at least one activity before continuing")
    } else {
      setIsLoading(true)
      const { leafletsActionTalking, campaignNews } = values
      setPerson({
        ...person,
        leafletsActionTalking,
        campaignNews
      })
      sendVolunteerSignup(person, values)
        .then(() => {
          analytics.track("volunteer-application-completed", {
            plugins: {
              fbpixel: false
            }
          })
          analytics.track(
            "Purchase",
            { content_name: "volunteer" },
            {
              plugins: {
                all: false,
                fbpixel: true
              }
            }
          )
          push("/complete")
          resetPersonalDetails()
        })
        .catch(() => {
          setIsLoading(false)
          setScrollTrigger(value => value + 1)
          setFormError(
            "We're having issues submitting your details, please check your details and try again later"
          )
        })
    }
  }
  let step = 1
  if (person.join) {
    step++
    const branch = findBranch(person)
    if (branch && branch.payment_mode === "bypass") {
      step = step + 1
    } else {
      step = step + 2
    }
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <RunIdleTimer resetPersonalDetails={resetPersonalDetails} />
      <Layout
        heading="Volunteering Details"
        sectionStep={step}
        person={person}
        volunteerOnly={volunteerOnly}
        scrollTrigger={scrollTrigger}
        setPerson={setPerson}
      >
        {topError && (
          <Cell span={[4, 8, 12]}>
            <Notification
              overrides={{ Body: { style: { width: "auto" } } }}
              kind="negative"
            >
              {topError}
            </Notification>
          </Cell>
        )}
        <Cell span={[4, 8, 12]}>
          <FormControl
            error={formError}
            label="I will help out on Australian Unions' campaigns by:"
          >
            <>
              <Controller
                control={control}
                name="leafletsActionTalking"
                as={Checkbox}
                onChange={([e]) => e.currentTarget.checked}
                valueName="checked"
              >
                Handing out leaflets, talking to people or attending a campaign
                action
              </Controller>
              <Controller
                control={control}
                name="campaignNews"
                as={Checkbox}
                onChange={([e]) => e.currentTarget.checked}
                valueName="checked"
              >
                Signing up for Australian Unions campaign updates
              </Controller>
            </>
          </FormControl>
        </Cell>
        <Cell span={[4, 8, 12]}>
          <Button isLoading={isLoading}>Finish</Button>
        </Cell>
      </Layout>
    </form>
  )
}
