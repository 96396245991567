import { Client as Styletron } from "styletron-engine-atomic"
import { createTheme, lightThemePrimitives } from "baseui"
import { getConstant } from "../../constants"

const white = "#FFFFFF"
const black = "#000000"
const silver = "#CCCCCC"

const hunterOrange = "#de761c"
const hunterBlue = "#00588a"
const dustyGray = "#333333"

export const palette = {
  white: white,
  black: black,
  primary: hunterBlue,
  secondary: dustyGray,
  tertiary: silver
}

export const engine = new Styletron({ prefix: "join-" })

// header font
export const spinnaker = engine.renderFontFace({
  fontFamily: "spinnaker",
  fontStyle: "normal",
  fontWeight: 400,
  src: `
    local('Spinnaker'),
    url('${getConstant(
      "publicURL"
    )}/fonts/Spinnaker-Regular.ttf') format('truetype')
  `
})

const mainTypography = {
  color: dustyGray,
  fontWeight: 300,
  fontSize: "18px"
}

const headerTypography = {
  color: hunterOrange,
  fontFamily: "spinnaker",
  fontSize: "25px"
}

export const theme = createTheme(
  {
    ...lightThemePrimitives,
    primaryFontFamily: "helvetica, sans-serif"
  },
  {
    colors: {
      buttonSecondaryFill: white,
      buttonSecondaryText: black,
      buttonSecondarySelectedFill: hunterBlue,
      buttonSecondarySelectedText: white,
      buttonPrimaryFill: hunterBlue,
      inputFill: white,
      tickFill: white,
      tickFillActive: silver,
      tickFillHover: silver,
      tickFillSelected: hunterOrange,
      tickFillSelectedHover: hunterOrange,
      tickFillSelectedHoverActive: hunterOrange,
      inputBorder: dustyGray
    },
    borders: {
      inputBorderRadius: "5px"
    },
    typography: {
      ParagraphXSmall: mainTypography,
      ParagraphSmall: mainTypography,
      ParagraphMedium: mainTypography,
      ParagraphLarge: mainTypography,
      LabelXSmall: mainTypography,
      LabelSmall: mainTypography,
      LabelMedium: mainTypography,
      LabelLarge: mainTypography,
      font950: headerTypography
    }
  }
)
