import React, { useEffect } from "react"
import { Provider as StyletronProvider } from "styletron-react"
import { BaseProvider } from "baseui"
import { Switch, Route, useLocation } from "react-router-dom"
import { PersonalDetailsForm } from "./pages/PersonalDetailsForm"
import { EmploymentDetailsForm, JobType } from "./pages/EmploymentDetailsForm"
import { CoverageLookupForm } from "./pages/CoverageLookupForm"
import { CompletePage } from "./pages/CompletePage"
import { ExpiredPage } from "./pages/ExpiredPage"
import { VolunteerDetailsForm } from "./pages/VolunteerDetailsForm"
import { ShareForm } from "./pages/ShareForm"
import { ShareComplete } from "./pages/ShareComplete"
import { Value } from "baseui/select"
import { AnalyticsTracker } from "./analytics"
import { PaymentForm } from "./pages/PaymentForm"
import { UnionForm } from "./pages/UnionForm"
import {
  UnionAdditionalFieldsForm,
  NTEUFormValues
} from "./pages/UnionAdditionalFieldsForm"
import { getConstant } from "./constants"
import { theme, engine } from "./configurables"
import { UnionNetsuiteForm } from "./pages/UnionNetsuiteForm"

export interface Person {
  full_name?: string
  email?: string
  address?: {
    label: string
    value?: Value
  }
  job_search?: {
    label: string
    value: Value | null | undefined
  }
  branch_id?: string | null
  netsuite_branch_id?: string | null
  suburb?: string | null
  postcode?: string
  volunteer?: boolean
  join?: boolean
  mobile?: string
  gender?: "f" | "m" | "o" | "n"
  date_of_birth?: Date[]
  job_title?: string
  duties?: string
  employer?: string
  agree?: boolean
  person_with_disability?: boolean
  lgbtiqa?: boolean
  atsi?: boolean
  other_language?: boolean
  industry_1?: Value | null
  industry_2?: Value | null
  industry_3?: Value | null
  industry_4?: Value | null
  leafletsActionTalking?: boolean
  campaignNews?: boolean
  utm_campaign?: string | null
  utm_medium?: string | null
  utm_source?: string | null
  utm_content?: string | null
  referer?: string | null
  source?: string | null
  person_id?: string
  people_id?: string
  bsb?: string
  accountNo?: string
  accountName?: string
  hours_per_week?: number
  // Not sent to API, just used within form
  employer_address?: string | null
  employer_postcode?: string | null
  employer_suburb?: string | null
  pay?: number
  income_band?: string | null
  job_type?: JobType
  cost?: number
  payment_type?: "cc" | "dd"
  cant_find_job?: boolean
  last_work_details_path?: string
  additional?: NTEUFormValues | null
  filledOutEmployerDetails?: boolean
  formSubmitted?: boolean
}

function App({
  volunteerOnly,
  coverageState,
  full_name,
  email,
  mobile,
  utm_campaign,
  utm_medium,
  utm_source,
  utm_content,
  referer,
  source,
  additional
}: {
  volunteerOnly: boolean
  coverageState?: string | null
  full_name?: string
  email?: string
  mobile?: string
  utm_campaign?: string | null
  utm_medium?: string | null
  utm_source?: string | null
  utm_content?: string | null
  source?: string | null
  referer?: string | null
  // Idea with the below is if we have other Unions that require other fields
  // we pass in NTEUFormValues | CMFEUFormValues etc.
  additional?: NTEUFormValues
}) {
  const coverageOnly = getConstant("coverageOnly")
  const disableSessionStorage = getConstant("disableSessionStorage")
  const volunteerEnabled = getConstant("volunteerEnabled")
  const emptyPersonalDetails: Person = {
    volunteer: volunteerOnly,
    join: !(volunteerEnabled || volunteerOnly || coverageOnly),
    full_name,
    email,
    mobile,
    utm_campaign,
    utm_medium,
    utm_source,
    utm_content,
    referer,
    source,
    additional,
    formSubmitted: false
  }

  let initialPerson: Person = {
    ...emptyPersonalDetails
  }
  if (!volunteerOnly && !coverageOnly && !disableSessionStorage) {
    const joinSessionStorage = sessionStorage.getItem("join")
    if (joinSessionStorage) {
      initialPerson = JSON.parse(joinSessionStorage)
      if (initialPerson.date_of_birth) {
        initialPerson.date_of_birth = initialPerson.date_of_birth.map(date => {
          return new Date(date)
        })
      }
    }
  }
  const [person, setPerson] = React.useState<Person>(initialPerson)
  React.useEffect(() => {
    if (!disableSessionStorage) {
      if (!person.people_id) {
        sessionStorage.setItem("join", "")
      } else {
        sessionStorage.setItem("join", JSON.stringify(person))
      }
    }
  }, [person, disableSessionStorage])

  const resetPersonalDetails = (completeReset?: boolean) => {
    setPerson(oldPerson => {
      if (completeReset) {
        return emptyPersonalDetails
      } else {
        return {
          // people_id and person_id are preserved because they are required by the ShareForm page
          ...emptyPersonalDetails,
          formSubmitted: true,
          people_id: oldPerson.people_id,
          person_id: oldPerson.person_id,
          // The payment, industry and job_search attributes are used to communicate to the joiner how they have paid
          payment_type: oldPerson.payment_type,
          branch_id: oldPerson.branch_id,
          industry_1: oldPerson.industry_1,
          industry_2: oldPerson.industry_2,
          industry_3: oldPerson.industry_3,
          industry_4: oldPerson.industry_4,
          job_search: oldPerson.job_search,
          filledOutEmployerDetails: oldPerson.filledOutEmployerDetails
        }
      }
    })
  }

  // Completely reset personal details
  // if the user navigates to an earlier part of the form after submitting
  const { pathname } = useLocation()

  useEffect(() => {
    if (
      person.formSubmitted &&
      pathname !== "/complete" &&
      pathname !== "/share" &&
      pathname !== "/expired"
    ) {
      setPerson(emptyPersonalDetails)
    }
  }, [pathname, setPerson, person.formSubmitted, emptyPersonalDetails])

  return (
    <>
      <AnalyticsTracker person={person} />
      <StyletronProvider value={engine}>
        <BaseProvider theme={theme}>
          <Switch>
            <Route path="/" exact>
              <PersonalDetailsForm
                volunteerOnly={volunteerOnly}
                setPerson={setPerson}
                resetPersonalDetails={resetPersonalDetails}
                person={person}
              />
            </Route>
            <Route path="/employment-details" exact>
              <EmploymentDetailsForm
                setPerson={setPerson}
                resetPersonalDetails={resetPersonalDetails}
                person={person}
                manual={false}
              />
            </Route>
            <Route path="/manual-employment-details" exact>
              <EmploymentDetailsForm
                setPerson={setPerson}
                resetPersonalDetails={resetPersonalDetails}
                person={person}
                manual={true}
              />
            </Route>
            <Route path="/union" exact>
              <UnionForm
                person={person}
                setPerson={setPerson}
                resetPersonalDetails={resetPersonalDetails}
              />
            </Route>
            {getConstant("additionalFieldsEnabled") && (
              <Route path="/union-additional-fields" exact>
                <UnionAdditionalFieldsForm
                  person={person}
                  setPerson={setPerson}
                  resetPersonalDetails={resetPersonalDetails}
                />
              </Route>
            )}
            <Route path="/netsuite-form" exact>
              <UnionNetsuiteForm
                person={person}
                setPerson={setPerson}
              ></UnionNetsuiteForm>
            </Route>
            <Route path="/payment" exact>
              <PaymentForm
                person={person}
                setPerson={setPerson}
                resetPersonalDetails={resetPersonalDetails}
              />
            </Route>
            <Route path="/volunteer-details" exact>
              <VolunteerDetailsForm
                volunteerOnly={volunteerOnly}
                person={person}
                setPerson={setPerson}
                resetPersonalDetails={resetPersonalDetails}
              />
            </Route>
            <Route path="/complete" exact>
              <CompletePage
                volunteerOnly={volunteerOnly}
                setPerson={setPerson}
                resetPersonalDetails={resetPersonalDetails}
                person={person}
              />
            </Route>
            <Route path="/expired" exact>
              <ExpiredPage
                person={person}
                setPerson={setPerson}
                volunteerOnly={volunteerOnly}
              />
            </Route>
            <Route path="/coverage" exact>
              <CoverageLookupForm
                setPerson={setPerson}
                person={person}
                manual={false}
                coverageState={coverageState}
              />
            </Route>
            <Route path="/manual-coverage" exact>
              <CoverageLookupForm
                setPerson={setPerson}
                person={person}
                manual={true}
                coverageState={coverageState}
              />
            </Route>
            <Route path="/share" exact>
              <ShareForm
                person={person}
                resetPersonalDetails={resetPersonalDetails}
              />
            </Route>
            <Route path="/share-complete" exact>
              <ShareComplete
                person={person}
                resetPersonalDetails={resetPersonalDetails}
              />
            </Route>
          </Switch>
        </BaseProvider>
      </StyletronProvider>
    </>
  )
}

export default App
