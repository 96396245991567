import * as React from "react"
import { Button } from "./Button"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "baseui/modal"
import { HeadingLevel, Heading } from "baseui/heading"

interface OKModalProps {
  close: () => void
  isOpen: boolean
  title: string | React.ReactNode | React.ReactNodeArray
  body: React.ReactNode | React.ReactNodeArray
  buttonText: string
}

export const OKModal = ({
  close,
  isOpen,
  title,
  body,
  buttonText
}: OKModalProps) => {
  return (
    <Modal
      overrides={{ Root: { style: { zIndex: 100 } } }}
      onClose={close}
      isOpen={isOpen}
      size="auto"
    >
      <HeadingLevel>
        <ModalHeader>
          <Heading styleLevel={5}>{title}</Heading>
        </ModalHeader>
        <ModalBody $style={{ maxHeight: "50vh", overflowY: "scroll" }}>
          {body}
        </ModalBody>
        <ModalFooter>
          <Button onClick={close}>{buttonText}</Button>
        </ModalFooter>
      </HeadingLevel>
    </Modal>
  )
}
