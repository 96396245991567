import { Client as Styletron } from "styletron-engine-atomic"
import { createTheme, lightThemePrimitives } from "baseui"
import { getConstant } from "../../constants"
import { postData } from "../../api"
import { FormValues } from "../../pages/PersonalDetailsForm"

const white = "#FFFFFF"
const black = "#000000"
const darkBlue = "#005691"
const dustyGray = "#333333"
const silver = "#CCCCCC"
// const lightBlue = "#5AA3CC"
// const red = "#B60F3D"
const brightRed = "#E3173F"

export const palette = {
  white: white,
  black: black,
  primary: darkBlue,
  secondary: dustyGray,
  tertiary: silver
}

export const engine = new Styletron({ prefix: "join-" })
export const arvo = engine.renderFontFace({
  fontFamily: "Arvo",
  fontStyle: "normal",
  fontWeight: 400,
  src: `local('Arvo-Regular'),
  url('${getConstant("publicURL")}/fonts/Arvo-Regular.ttf') format('truetype')`
})

export const arvoBold = engine.renderFontFace({
  fontFamily: "Arvo",
  fontStyle: "bold",
  fontWeight: 700,
  src: `local('Arvo-Bold'),
  url('${getConstant("publicURL")}/fonts/Arvo-Bold.ttf') format('truetype')`
})

export const roboto = engine.renderFontFace({
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 400,
  src: `local('Roboto-Regular'),
  url('${getConstant(
    "publicURL"
  )}/fonts/Roboto-Regular.ttf') format('truetype')`
})

export const robotoBold = engine.renderFontFace({
  fontFamily: "Roboto",
  fontStyle: "bold",
  fontWeight: 700,
  src: `local('Roboto-Bold'),
  url('${getConstant("publicURL")}/fonts/Roboto-Bold.ttf') format('truetype')`
})

const mainTypography = {
  color: black,
  fontSize: "18px"
}

const headerTypography = {
  color: palette.primary,
  fontFamily: "Arvo",
  fontSize: "25px"
}

export const theme = createTheme(
  {
    ...lightThemePrimitives,
    primaryFontFamily: "Roboto"
  },
  {
    colors: {
      buttonSecondaryFill: white,
      buttonSecondaryText: black,
      buttonSecondarySelectedFill: darkBlue,
      buttonSecondarySelectedText: white,
      buttonPrimaryFill: brightRed,
      inputFill: white,
      tickFill: white,
      tickFillActive: silver,
      tickFillHover: silver,
      tickFillSelected: darkBlue,
      tickFillSelectedHover: darkBlue,
      tickFillSelectedHoverActive: darkBlue,
      inputBorder: dustyGray
    },
    borders: {
      inputBorderRadius: "5px"
    },
    typography: {
      ParagraphXSmall: mainTypography,
      ParagraphSmall: mainTypography,
      ParagraphMedium: mainTypography,
      ParagraphLarge: mainTypography,
      LabelXSmall: mainTypography,
      LabelSmall: mainTypography,
      LabelMedium: mainTypography,
      LabelLarge: mainTypography,
      font950: headerTypography
    }
  }
)

export const extraStyles = {
  primaryButton: {
    fontFamily: "Arvo",
    fontSize: "16px",
    fontWeight: "bold"
  }
} as const

const genderMap = {
  m: "Male",
  f: "Female",
  o: "Other",
  n: "Prefer not to say"
}

export const sendJoinerToActionNetwork = (joiner: FormValues) => {
  const dob = joiner.date_of_birth ? joiner.date_of_birth[0] : undefined
  const split_name = joiner.full_name.split(" ")
  const data = {
    person: {
      family_name: split_name.slice(1).join(" "),
      given_name: split_name[0],
      postal_addresses: [
        {
          postal_code: joiner.postcode,
          address_lines: [joiner.address.label],
          locality: joiner.suburb
        }
      ],
      email_addresses: [
        {
          address: joiner.email
        }
      ],
      phone_numbers: [
        {
          number: joiner.mobile,
          number_type: "mobile",
          status: "subscribed"
        }
      ],
      custom_fields: {
        "ST: Gender Identity": genderMap[joiner.gender],
        DOB: dob?.toLocaleDateString("en-AU"),
        "Extra languages": joiner.other_language || false,
        LGBT: joiner.lgbtiqa || false,
        PWD: joiner.person_with_disability || false,
        ATSI: joiner.atsi || false
      }
    },
    add_tags: ["A: Signup online join your union"],
    triggers: {
      autoresponse: {
        enabled: false
      }
    }
  }
  const endpoint = getConstant("joinApi") + "/join/v2/unionsnsw"
  return postData(endpoint, {}, data)
}
