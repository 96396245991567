import { ButtonProps } from "baseui/button"
import {
  ButtonGroup as BaseButtonGroup,
  ButtonGroupProps,
  ButtonGroupOverrides,
  MODE
} from "baseui/button-group"
import React from "react"
import withOverrides from "../overrides"
import { Button, SharedStylePropsT } from "./Button"
import { ButtonOverrides } from "baseui/button"
import { StyleObject } from "styletron-react"
import { palette } from "../configurables"

const rightButtonOverrides: ButtonOverrides = {
  BaseButton: {
    style: (props: SharedStylePropsT) => {
      const style: StyleObject = {
        borderRightColor: palette.secondary,
        borderRightStyle: "solid",
        borderTopLeftRadius: "0",
        borderBottomLeftRadius: "0"
      }
      return style
    }
  }
}

export const RightButton = withOverrides<ButtonProps>(
  Button,
  rightButtonOverrides,
  true
)
const buttonGroupOverrides: ButtonGroupOverrides = {
  Root: {
    style: {
      flexWrap: "nowrap"
    }
  }
}

export const ButtonGroup = withOverrides<ButtonGroupProps>(
  BaseButtonGroup,
  buttonGroupOverrides,
  true
)

interface ButtonMapping {
  value: string
  display: string
}
interface ButtonRadioProps {
  mappings: ButtonMapping[]
  updateFunction?: (value: string) => void
  currentValue?: string
}

export const ButtonRadios = ({
  mappings,
  updateFunction,
  currentValue
}: ButtonRadioProps) => {
  return (
    <ButtonGroup
      mode={MODE.radio}
      selected={mappings.findIndex(x => x.value === currentValue)}
      onClick={(
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        index
      ) => {
        if (updateFunction) {
          updateFunction(mappings[index].value)
        }
      }}
    >
      {mappings.map((mapping, index) => {
        // Need to use RightButton on the last button
        const Component = index === mappings.length - 1 ? RightButton : Button
        return (
          <Component
            data-testid={`button-radio-${mappings[index].value}`}
            key={mapping.value}
            type={"button"}
          >
            {mapping.display}
          </Component>
        )
      })}
    </ButtonGroup>
  )
}
