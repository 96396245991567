// based on embed code suggested by Inspectlet

const ReactInspectlet = {
  initialize: (code, options) => {
    if (!code) {
      return
    }

    window.__insp = window.__insp || []

    window.__insp.push(["wid", code])

    if (options && options.debug) {
      window.__insp.push(["debug", true])
    }

    var ldinsp = function() {
      if (typeof window.__inspld != "undefined") return
      window.__inspld = 1
      var insp = document.createElement("script")
      insp.type = "text/javascript"
      insp.async = true
      insp.id = "inspsync"
      insp.src =
        ("https:" === document.location.protocol ? "https" : "http") +
        `://cdn.inspectlet.com/inspectlet.js?wid=${code}&r=` +
        Math.floor(new Date().getTime() / 3600000)
      var x = document.getElementsByTagName("script")[0]
      x.parentNode.insertBefore(insp, x)
    }
    setTimeout(ldinsp, 0)
  }
}

export default ReactInspectlet
