import React from "react"
import { mergeOverrides } from "baseui"
import { Overrides } from "baseui/overrides"
// TODO: Let's make this an NPM module
interface HasOverrides<T> {
  overrides?: T
}

// Pass a baseweb component and overrides and it will return a
// component that has those overrides by default
export default function withOverrides<
  TProps extends HasOverrides<TProps["overrides"]>
>(
  Component: React.JSXElementConstructor<TProps>,
  globalOverrides: TProps["overrides"],
  forwardRef: boolean = false
) {
  const WithOverrides = React.forwardRef((props: TProps, ref) => {
    const { overrides, ...rest } = props
    let finalOverrides: TProps["overrides"] = {}
    if (overrides) {
      // Have to cast these here. Is there a way to make it know these are overrides?
      finalOverrides = mergeOverrides(
        globalOverrides as Overrides<unknown>,
        overrides as Overrides<unknown>
      )
    } else {
      finalOverrides = globalOverrides
    }

    if (forwardRef) {
      return (
        <Component {...(rest as TProps)} ref={ref} overrides={finalOverrides} />
      )
    } else {
      return <Component {...(rest as TProps)} overrides={finalOverrides} />
    }
    // See why we do the cast here: https://github.com/Microsoft/TypeScript/issues/28938
  })

  WithOverrides.displayName = `withOverrides(${(Component as any).displayName ||
    Component.name})`

  return WithOverrides
}
