import { Client as Styletron } from "styletron-engine-atomic"
import { createTheme, lightThemePrimitives } from "baseui"
import { getConstant } from "../../constants"

const white = "#FFFFFF"
const black = "#000000"
const silver = "#CCCCCC"

const qcuRed = "#bd2032"
const dustyGray = "#333333"

export const palette = {
  white: white,
  black: black,
  primary: qcuRed,
  secondary: dustyGray,
  tertiary: silver
}

export const engine = new Styletron({ prefix: "join-" })

// header font
export const interRegular = engine.renderFontFace({
  fontFamily: "inter",
  fontStyle: "normal",
  fontWeight: 400,
  src: `
    local('Inter-Regular'), local('Inter Regular'),
    url('${getConstant(
      "publicURL"
    )}/fonts/Inter-Regular.ttf') format('truetype')
  `
})

export const interBold = engine.renderFontFace({
  fontFamily: "inter",
  fontStyle: "normal",
  fontWeight: 700,
  src: `
    local('Inter-Bold'), local('Inter Bold'),
    url('${getConstant("publicURL")}/fonts/Inter-Bold.ttf') format('truetype')
  `
})

const mainTypography = {
  color: black,
  fontWeight: 300,
  fontSize: "16px"
}

const headerTypography = {
  color: qcuRed,
  fontWeight: 700,
  fontSize: "20px"
}

export const theme = createTheme(
  {
    ...lightThemePrimitives,
    primaryFontFamily: "inter, sans-serif"
  },
  {
    colors: {
      buttonSecondaryFill: white,
      buttonSecondaryText: black,
      buttonSecondarySelectedFill: qcuRed,
      buttonSecondarySelectedText: white,
      buttonPrimaryFill: qcuRed,
      inputFill: white,
      tickFill: white,
      tickFillActive: silver,
      tickFillHover: silver,
      tickFillSelected: qcuRed,
      tickFillSelectedHover: qcuRed,
      tickFillSelectedHoverActive: qcuRed,
      inputBorder: dustyGray
    },
    borders: {
      inputBorderRadius: "5px"
    },
    typography: {
      ParagraphXSmall: mainTypography,
      ParagraphSmall: mainTypography,
      ParagraphMedium: mainTypography,
      ParagraphLarge: mainTypography,
      LabelXSmall: mainTypography,
      LabelSmall: mainTypography,
      LabelMedium: mainTypography,
      LabelLarge: mainTypography,
      font950: headerTypography
    }
  }
)
