import React, { useEffect } from "react"
import { Person } from "../App"
import { NetsuiteIFrame } from "../components/NetsuiteIframe"
import { Layout } from "../components/Layout"

interface UnionNetsuiteFormProps {
  setPerson: (person: Person | ((oldPerson: Person) => Person)) => void
  person: Person
}

export const UnionNetsuiteForm = ({
  setPerson,
  person
}: UnionNetsuiteFormProps) => {
  useEffect(() => {
    // when it mounts, null the Branch ID so that there isn't an issue of someone going back then forward
    // and being signed up to the wrong branch
    setPerson((oldPerson: Person) => {
      return {
        ...oldPerson,
        branch_id: null
      }
    })
  }, [setPerson])
  return (
    <>
      <Layout
        heading="Complete your application"
        sectionStep={3}
        person={person}
        setPerson={setPerson}
      >
        <></>
      </Layout>
      <NetsuiteIFrame {...person}></NetsuiteIFrame>
    </>
  )
}
