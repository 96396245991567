import { Client as Styletron } from "styletron-engine-atomic"
import { createTheme, lightThemePrimitives } from "baseui"

const white = "#FFFFFF"
const black = "#000000"
const silver = "#CCCCCC"

const dustyGray = "#333333" // body text
const darkGray = "#252525" // header text
const redAccent = "#CD171E" // accent

export const palette = {
  white: white,
  black: black,
  primary: redAccent,
  secondary: dustyGray,
  tertiary: silver
}

export const engine = new Styletron({ prefix: "join-" })

const mainTypography = {
  color: dustyGray,
  fontWeight: 500,
  fontSize: "18px"
}

const headerTypography = {
  color: darkGray,
  fontWeight: 900,
  fontSize: "25px"
}

export const theme = createTheme(
  {
    ...lightThemePrimitives,
    primaryFontFamily: "sans-serif"
  },
  {
    colors: {
      buttonSecondaryFill: white,
      buttonSecondaryText: black,
      buttonSecondarySelectedFill: redAccent,
      buttonSecondarySelectedText: white,
      buttonPrimaryFill: redAccent,
      inputFill: white,
      tickFill: white,
      tickFillActive: silver,
      tickFillHover: silver,
      tickFillSelected: redAccent,
      tickFillSelectedHover: redAccent,
      tickFillSelectedHoverActive: redAccent,
      inputBorder: dustyGray
    },
    borders: {
      inputBorderRadius: "5px"
    },
    typography: {
      ParagraphXSmall: mainTypography,
      ParagraphSmall: mainTypography,
      ParagraphMedium: mainTypography,
      ParagraphLarge: mainTypography,
      LabelXSmall: mainTypography,
      LabelSmall: mainTypography,
      LabelMedium: mainTypography,
      LabelLarge: mainTypography,
      font950: headerTypography
    }
  }
)
