import React from "react"
import { ParagraphMedium } from "baseui/typography"
import { useIdleTimer } from "react-idle-timer"
import { OKModal } from "./OKModal"
import { useHistory } from "react-router-dom"
import { getConstant } from "../constants"

interface IdleTimerProps {
  resetPersonalDetails: (completeReset?: boolean) => void
}
export const ExpireWarningModal = ({
  isOpen,
  close
}: {
  isOpen: boolean
  close: () => void
}) => {
  return (
    <OKModal
      close={close}
      isOpen={isOpen}
      body={
        <>
          <ParagraphMedium>
            Your session is about to time out. Click OK to resume joining your
            union.
          </ParagraphMedium>
        </>
      }
      title="Session about to expire"
      buttonText="OK"
    />
  )
}
export const RunIdleTimer = ({ resetPersonalDetails }: IdleTimerProps) => {
  const sessionTimeoutSec = getConstant("sessionTimeout")
  const warningTimeoutSec = getConstant("warningTimeout")
  const history = useHistory()
  const [isTimerModalOpen, setIsTimerModalOpen] = React.useState(false)

  // convert timeout settings from seconds to milliseconds
  let sessionTimeout = 0
  let warningTimeout = 0
  if (sessionTimeoutSec > 0) {
    sessionTimeout = sessionTimeoutSec * 1000
  }
  if (warningTimeoutSec > 0) {
    warningTimeout = warningTimeoutSec * 1000
  }

  const handleModalClose = () => {
    setIsTimerModalOpen(false)
  }
  const handleOnIdle = () => {
    if (sessionTimeout > 0) {
      setIsTimerModalOpen(true)
      setTimeout(handleFinalAction, warningTimeout)
    }
  }

  const { reset, getRemainingTime } = useIdleTimer({
    timeout: sessionTimeout,
    onIdle: handleOnIdle
  })

  const handleFinalAction = () => {
    if (getRemainingTime() === 0) {
      history.push("/expired")
      resetPersonalDetails()
    } else {
      reset()
    }
  }

  if (sessionTimeout > 0) {
    return (
      <>
        <ExpireWarningModal
          isOpen={isTimerModalOpen}
          close={handleModalClose}
        />
      </>
    )
  } else {
    return null
  }
}
