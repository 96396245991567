import * as React from "react"
import { Radio as BaseRadio, RadioProps, RadioOverrides } from "baseui/radio"
import withOverrides from "../overrides"
import { palette } from "../configurables"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { useStyletron } from "baseui"

const Check = (props: any) => {
  const [css] = useStyletron()
  if (props.$checked) {
    return (
      <FontAwesomeIcon
        icon={faCheck}
        className={css({
          color: "white",
          height: "20px",
          width: "20px",
          fontSize: "20px"
        })}
      />
    )
  }
  return null
}
export const globalOverrides: RadioOverrides = {
  Description: {
    style: {
      fontSize: "16px",
      maxWidth: "auto",
      marginTop: "8px",
      color: palette.secondary,
      lineHeight: "24px",
      marginLeft: "44px"
    }
  },
  RadioMarkInner: {
    component: Check
  },
  RadioMarkOuter: {
    style: props => {
      let bgColor = "white"
      if (
        props.$isHovered ||
        props.$isActive ||
        props.$isFocused ||
        props.$isFocusVisible
      ) {
        bgColor = palette.tertiary
      }
      if (props.$checked) {
        bgColor = palette.primary
      }
      const borderColor = props.$checked ? palette.primary : palette.secondary
      return {
        borderLeftColor: borderColor,
        borderRightColor: borderColor,
        borderTopColor: borderColor,
        borderBottomColor: borderColor,
        backgroundColor: bgColor,
        borderLeftWidth: "2px",
        borderRightWidth: "2px",
        borderTopWidth: "2px",
        borderBottomWidth: "2px",
        borderLeftStyle: "solid",
        borderRightStyle: "solid",
        borderTopStyle: "solid",
        borderBottomStyle: "solid",
        width: "28px",
        height: "28px"
      }
    }
  }
}

export const Radio = withOverrides<RadioProps>(BaseRadio, globalOverrides)
