import React from "react"
import { FormControl } from "./FormControl"
import { Input } from "./Input"
import { SearchSelect } from "./Select"
import { Value } from "baseui/select"
import { FieldError } from "react-hook-form"
import * as Sentry from "@sentry/browser"
import { getConstant } from "../constants"
import { GoogleApiWrapper, GoogleAPI } from "google-maps-react"
import usePlacesAutocomplete, {
  Suggestion,
  GeocodeResult,
  getGeocode,
  getLatLng
} from "use-places-autocomplete"

function suggestionsToOptions(suggestions: Suggestion[]) {
  return suggestions.map(suggestion => {
    const { place_id, description } = suggestion
    return {
      id: place_id,
      label: description,
      suggestion: suggestion
    }
  })
}
// process a geocoding response from google API
// if it was a suburb lookup, it may or may not contain a postcode
interface ParseAddressInterface {
  result: GeocodeResult
  setPostcode: (postcode: string) => void
  setSuburb: (suburb: string | null) => void
}
const parseAddress = ({
  result,
  setPostcode,
  setSuburb
}: ParseAddressInterface) => {
  let didSetPostcode = false
  result.address_components.forEach(({ long_name, types }) => {
    if (types.includes("postal_code")) {
      setPostcode(long_name)
      didSetPostcode = true
    }
    if (types.includes("locality")) {
      setSuburb(long_name)
    }
  })
  return didSetPostcode
}

interface AddressSearchProps {
  addressType: "address" | "postcode"
  onChange: (params: { label: string; value: Value | null } | null) => void
  inputValue?: {
    label: string
    value?: Value
  }
  setPostcode: (postcode: string) => void
  setSuburb: (suburb: string | null) => void
  error: FieldError | undefined
  google: GoogleAPI // we aren't actually using this
  fieldLabel: string
  fieldName: string
}
type FallbackAddressSearchProps = Exclude<AddressSearchProps, "google">
const FallbackAddressSearch = ({
  onChange,
  inputValue,
  fieldLabel,
  fieldName,
  error
}: FallbackAddressSearchProps) => {
  return (
    <FormControl error={error && error.message} label={fieldLabel}>
      <Input
        error={!!error}
        value={inputValue && inputValue.label ? inputValue.label : ""}
        onChange={e => {
          if (e.currentTarget.value) {
            onChange({
              label: e.currentTarget.value,
              value: null
            })
          } else {
            onChange(null)
          }
        }}
        overrides={{ InputContainer: { style: { maxWidth: "500px" } } }}
        id={fieldName}
        name={fieldName}
      />
    </FormControl>
  )
}
const AddressSearch = ({
  addressType,
  fieldLabel,
  onChange,
  inputValue,
  setPostcode,
  setSuburb,
  error
}: AddressSearchProps) => {
  const requestType = addressType === "postcode" ? "(regions)" : "address"
  const {
    suggestions: { data },
    setValue
  } = usePlacesAutocomplete({
    requestOptions: {
      types: [requestType],
      componentRestrictions: {
        country: ["au", "cx", "hm", "nf"]
      }
    },
    debounce: 50
  })
  return (
    <FormControl
      error={error && error.message}
      label={fieldLabel}
      overrides={{ ControlContainer: { style: { maxWidth: "500px" } } }}
    >
      <SearchSelect
        onBlurResetsInput={false}
        onCloseResetsInput={false}
        error={!!error}
        openOnClick={false}
        type={"search"}
        placeholder={""}
        filterOptions={options => options}
        options={suggestionsToOptions(data)}
        clearable
        value={inputValue && inputValue.value ? inputValue.value : []}
        onChange={newValue => {
          if (newValue.option && newValue.option.suggestion.description) {
            getGeocode({
              placeId: newValue.option && newValue.option.suggestion.place_id
            })
              .then(result => {
                let hasPostcode = parseAddress({
                  result: result[0],
                  setPostcode: setPostcode,
                  setSuburb: setSuburb
                })
                // If we didn't get a postcode from that result,
                // extract lat/lng coordinates from it
                // and do another lookup, which will have a postcode
                if (!hasPostcode) {
                  getLatLng(result[0]).then(latLng => {
                    getGeocode({ location: latLng }).then(nextresult => {
                      parseAddress({
                        result: nextresult[0],
                        setPostcode: setPostcode,
                        setSuburb: setSuburb
                      })
                    })
                  })
                }
              })
              .catch(reason => {
                Sentry.captureException(reason)
              })
            onChange({
              label: newValue.option && newValue.option.suggestion.description,
              value: newValue.value
            })
          } else {
            onChange(null)
          }
        }}
        onInputChange={e => {
          setSuburb(null)
          setValue(e.currentTarget.value)
          if (e.currentTarget.value) {
            onChange({ label: e.currentTarget.value, value: null })
          } else {
            onChange(null)
          }
        }}
        overrides={{
          ValueContainer: {
            style: { paddingLeft: "14px" }
          },
          Input: {
            props: {
              id: `address`,
              name: `address`
            }
          },
          SearchIconContainer: {
            style: { display: "none" }
          },
          Dropdown: {
            style: {
              maxHeight: "300px"
            }
          }
        }}
      />
    </FormControl>
  )
}

export const WrappedAddressSearch =
  getConstant("environment") === "test"
    ? FallbackAddressSearch
    : GoogleApiWrapper({
        apiKey: "AIzaSyD2j1kytdwZJ9muHrOs222c1WwSmb3ejQM",
        LoadingContainer: FallbackAddressSearch
      })(AddressSearch)
