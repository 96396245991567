import {
  FormControl as BaseFormControl,
  FormControlProps,
  FormControlOverrides
} from "baseui/form-control"
import withOverrides from "../overrides"
import { Theme } from "baseui/theme"

export const formControlPadding: FormControlOverrides = {
  Label: {
    style: {
      marginTop: "24px"
    }
  }
}

const globalOverrides: FormControlOverrides = {
  Label: {
    style: ({ $theme }: { $theme: Theme }) => {
      return {
        ...$theme.typography.font300
      }
    }
  }
}

export const FormControl = withOverrides<FormControlProps>(
  BaseFormControl,
  globalOverrides
)
