import { getQueryVariable } from "./util"

function asInt(value: string | undefined) {
  if (!value) {
    return 0
  }
  return parseInt(value)
}
function isTrue(value: string | undefined) {
  return value === "true"
}
const constants = {
  joinApi: process.env.STORYBOOK_API_URL || process.env.REACT_APP_API_URL,
  publicURL:
    process.env.STORYBOOK_ENVIRONMENT === "storybook"
      ? ""
      : process.env.REACT_APP_PUBLIC_URL,
  inspectletCode: process.env.REACT_APP_INSPECTLET_CODE,
  sentryURL: process.env.REACT_APP_SENTRY_URL,
  environment:
    process.env.REACT_APP_ENVIRONMENT || process.env.STORYBOOK_ENVIRONMENT,
  pixelId: process.env.REACT_APP_PIXEL_ID,
  segmentWriteKey: process.env.REACT_APP_SEGMENT_WRITE_KEY,
  googleAnalyticsId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
  volunteerEnabled: isTrue(process.env.REACT_APP_VOLUNTEER_ASK_ON_JOIN),
  coverageOnly: isTrue(process.env.REACT_APP_COVERAGE_ONLY),
  additionalFieldsEnabled: isTrue(process.env.REACT_APP_ADDITIONAL_FIELDS),
  autoconfirmEnabledForWA: isTrue(process.env.REACT_APP_AUTOCONFIRM_WA),
  autoconfirmEnabledForACT: isTrue(process.env.REACT_APP_AUTOCONFIRM_ACT),
  autoconfirmEnabledForNT: isTrue(process.env.REACT_APP_AUTOCONFIRM_NT),
  autoconfirmEnabledForSA: isTrue(process.env.REACT_APP_AUTOCONFIRM_SA),
  autoconfirmEnabledForVIC: isTrue(process.env.REACT_APP_AUTOCONFIRM_VIC),
  autoconfirmEnabledForNSW: isTrue(process.env.REACT_APP_AUTOCONFIRM_NSW),
  autoconfirmEnabledForTAS: isTrue(process.env.REACT_APP_AUTOCONFIRM_TAS),
  autoconfirmEnabledForQLD: isTrue(process.env.REACT_APP_AUTOCONFIRM_QLD),
  autoconfirmEnabledForOther: isTrue(process.env.REACT_APP_AUTOCONFIRM_OTHER),
  cloudPaymentsHost: process.env.REACT_APP_CLOUD_PAYMENTS_HOST || "",
  xmasBreakEnabled: isTrue(process.env.REACT_APP_XMAS_BREAK_ENABLED),
  sessionTimeout: asInt(process.env.REACT_APP_SESSION_TIMEOUT_SECONDS),
  warningTimeout: asInt(process.env.REACT_APP_WARNING_TIMEOUT_SECONDS),
  formVersion:
    process.env.STORYBOOK_FORM_VERSION || process.env.REACT_APP_FORM_VERSION,
  disableSessionStorage: isTrue(process.env.REACT_APP_DISABLE_SESSION_STORAGE),
  joinEventProcess: "Join Form",
  joinPersonalDetailsEvent: "Completed Personal Details",
  joinEmploymentDetailsEvent: "Completed Employment Details",
  joinCoverageConfirmEvent: "Union Coverage Confirmed",
  joinViaExternalAttempt: "External Form Join Began",
  joinUnionAdditionalFieldsEvent: "Union Additional Fields",
  joinCoverageUnableToConfirmEvent: "Unable to Confirm Union Coverage",
  joinBypassLinkEvent: "Bypass Link Clicked",
  joinPaymentDetailsEvent: "Payment Details Completed",
  maxShareFormFields: asInt(process.env.REACT_APP_MAX_SHARE_FORM_FIELDS)
}

export type AutoconfirmConfigs =
  | "autoconfirmEnabledForWA"
  | "autoconfirmEnabledForACT"
  | "autoconfirmEnabledForNT"
  | "autoconfirmEnabledForSA"
  | "autoconfirmEnabledForVIC"
  | "autoconfirmEnabledForNSW"
  | "autoconfirmEnabledForTAS"
  | "autoconfirmEnabledForQLD"
  | "autoconfirmEnabledForOther"

export function getConstant<K extends keyof typeof constants>(
  constant: K
): typeof constants[K] {
  if (
    constants.environment === "test" ||
    process.env.NODE_ENV === "development"
  ) {
    // See if we override any of the variables in query parameters for tests
    const value = getQueryVariable(constant, window.location.search)
    if (value) {
      if (typeof constants[constant] === "boolean") {
        return isTrue(value) as typeof constants[K]
      } else {
        return value as typeof constants[K]
      }
    }
  }
  return constants[constant]
}
