import React, { useEffect, useState } from "react"
import { Layout } from "../components/Layout"
import { Cell } from "baseui/layout-grid"
import { Button } from "../components/Button"
import { Select } from "../components/Select"
import { Checkbox } from "../components/Checkbox"
import { useForm, Controller } from "react-hook-form"
import { FormControl } from "../components/FormControl"
import { RunIdleTimer } from "../components/RunIdleTimer"
import { Input } from "../components/Input"
import { Person } from "../App"
import { Value } from "baseui/select"
import { Notification } from "baseui/notification"
import { sendSignup } from "../api"
import { useHistory } from "react-router-dom"

interface UnionAdditionalFieldsFormProps {
  setPerson: (person: Person | ((oldPerson: Person) => Person)) => void
  person: Person
  resetPersonalDetails: (completeReset?: boolean) => void
}

const paymentFrequencyOptions = [
  {
    id: "monthly",
    label: "Monthly"
  },
  {
    id: "quarterly",
    label: "Quarterly"
  },
  {
    id: "half-yearly",
    label: "Half-yearly"
  },
  {
    id: "yearly",
    label: "Yearly"
  }
]

const staffTypeOptions = [
  {
    id: "academic",
    label: "Academic"
  },
  {
    id: "general",
    label: "General"
  }
]

export interface NTEUFormValues {
  campus_name: string | null
  work_area: string | null
  payment_frequency: string | null
  staff_type: string | null
  former_nteu: boolean | undefined
}

interface SelectProps {
  value?: string
  onChange: (option: string) => void
}

const PaymentIntervalSelect = (props: SelectProps) => {
  let value: Value = []
  paymentFrequencyOptions.forEach(option => {
    if (option.id === props.value) {
      value = [option]
    }
  })
  return (
    <FormControl
      label="Desired Payment Frequency"
      overrides={{ ControlContainer: { style: { maxWidth: "300px" } } }}
    >
      <Select
        overrides={{
          Input: {
            props: {
              id: `payment_frequency`,
              name: `payment_frequency`
            }
          },
          Dropdown: {
            style: {
              maxHeight: "200px"
            }
          }
        }}
        placeholder={"Please Choose"}
        options={paymentFrequencyOptions}
        onChange={value => props.onChange(value.value[0].id as string)}
        value={value}
        required={true}
        backspaceRemoves={false}
        clearable={false}
        searchable={true}
      />
    </FormControl>
  )
}

const StaffTypeSelect = (props: SelectProps) => {
  let value: Value = []
  staffTypeOptions.forEach(option => {
    if (option.id === props.value) {
      value = [option]
    }
  })
  return (
    <FormControl
      label="Staff Type"
      overrides={{ ControlContainer: { style: { maxWidth: "300px" } } }}
    >
      <Select
        overrides={{
          Input: {
            props: {
              id: `staff_type`,
              name: `staff_type`
            }
          },
          Dropdown: {
            style: {
              maxHeight: "200px"
            }
          }
        }}
        placeholder={"Please Choose"}
        options={staffTypeOptions}
        onChange={value => props.onChange(value.value[0].id as string)}
        value={value}
        required={true}
        backspaceRemoves={false}
        clearable={false}
        searchable={true}
      />
    </FormControl>
  )
}

export const UnionAdditionalFieldsForm = ({
  setPerson,
  resetPersonalDetails,
  person
}: UnionAdditionalFieldsFormProps) => {
  const [formError, setFormError] = useState("")
  const [employer, setEmployer] = useState(person.employer)
  const { push } = useHistory()
  const { handleSubmit, control, register, errors } = useForm<NTEUFormValues>({
    defaultValues: {
      campus_name: person.additional?.campus_name || null,
      work_area: person.additional?.work_area || null,
      payment_frequency: person.additional?.payment_frequency || null,
      staff_type: person.additional?.staff_type || null,
      former_nteu: person.additional?.former_nteu || undefined
    }
  })
  const topError =
    formError ||
    (Object.keys(errors).length > 0
      ? "Please fill in the required information below"
      : "")

  useEffect(() => {
    // when it mounts, null the Branch ID so that there isn't an issue of someone going back then forward
    // and being signed up to the wrong branch
    setPerson((oldPerson: Person) => {
      return {
        ...oldPerson,
        branch_id: null
      }
    })
  }, [setPerson])
  const onSubmit = (values: NTEUFormValues) => {
    person.additional = values
    person.employer = employer
    sendSignup(person)
      .then(response => {
        setPerson({
          ...person
        })
        // not currently required
        // sendJoinEvent(
        //   response.person_id,
        //   response.people_id,
        //   getConstant("joinUnionAdditionalFieldsEvent")
        // )
        push(`/payment`)
      })
      .catch(() => {
        setFormError(
          "We're having issues submitting your details, please check your details and try again later"
        )
      })
  }
  return (
    <form id="union-additional-details" onSubmit={handleSubmit(onSubmit)}>
      <RunIdleTimer resetPersonalDetails={resetPersonalDetails} />
      <Layout
        heading="Additional Union Details"
        sectionStep={3}
        person={person}
        setPerson={setPerson}
      >
        {topError && (
          <Cell span={[4, 8, 12]}>
            <Notification
              overrides={{
                Body: { style: { width: "auto", marginBottom: "8px" } }
              }}
              kind="negative"
            >
              {topError}
            </Notification>
          </Cell>
        )}
        <Cell span={[4, 8, 12]}>
          <FormControl label={"Institution (employer)"}>
            <Input
              overrides={{ InputContainer: { style: { width: "300px" } } }}
              value={employer}
              onChange={e => setEmployer(e.currentTarget.value)}
              name="employer"
              id="employer"
            />
          </FormControl>
          <FormControl
            error={!!errors.campus_name}
            label={"Campus of the institution"}
            caption="Please enter the name of the campus of the institution that you work at"
          >
            <Input
              inputRef={register({ required: "Please enter campus name" })}
              overrides={{ InputContainer: { style: { width: "300px" } } }}
              name="campus_name"
              id="campus_name"
            />
          </FormControl>
          <FormControl
            label={"Work Area (optional)"}
            caption="Please enter the name of the department/school/faculty or building code"
          >
            <Input
              inputRef={register()}
              overrides={{ InputContainer: { style: { width: "300px" } } }}
              id="work_area"
              name="work_area"
            />
          </FormControl>
          <Controller
            as={PaymentIntervalSelect}
            control={control}
            name="payment_frequency"
          />
          <Controller
            as={StaffTypeSelect}
            control={control}
            name="staff_type"
          />
          <FormControl>
            <Controller
              control={control}
              name="former_nteu"
              as={Checkbox}
              onChange={([e]) => e.currentTarget.checked}
              valueName="checked"
            >
              Former NTEU Member
            </Controller>
          </FormControl>
        </Cell>

        <Button overrides={{ BaseButton: { style: { marginBottom: "0" } } }}>
          Next
        </Button>
      </Layout>
    </form>
  )
}
