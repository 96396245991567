import React from "react"
import { Layout } from "../components/Layout"
import { Cell } from "baseui/layout-grid"
import { Button } from "../components/Button"
import { useForm, Controller, FieldError } from "react-hook-form"
import { Input } from "../components/Input"
import { FormControl } from "../components/FormControl"
import { ButtonRadios } from "../components/ButtonRadios"
import { Datepicker } from "../components/Datepicker"
import { RunIdleTimer } from "../components/RunIdleTimer"
import { useHistory } from "react-router-dom"
import { WrappedAddressSearch } from "../components/AddressPicker"
import { Person } from "../App"
import { Value } from "baseui/select"
import { getStateByPostcode, isAutoconfirmState } from "../util"
import { Notification } from "baseui/notification"
import { Checkbox } from "../components/Checkbox"
import { useStyletron } from "baseui"
import { ParagraphSmall } from "baseui/typography"
import { sendJoinEvent, sendSignup } from "../api"
import { sendJoinerData } from "../configurables"
import { getConstant } from "../constants"
interface PersonalDetailsFormProps {
  setPerson: (person: Person | ((oldPerson: Person) => Person)) => void
  person: Person
  volunteerOnly: boolean
  resetPersonalDetails: (completeReset?: boolean) => void
}

export interface FormValues {
  full_name: string
  email: string
  address: {
    label: string
    value?: Value
  }
  suburb: string | null
  postcode: string
  mobile: string
  gender: "f" | "m" | "o" | "n"
  date_of_birth: Date[]
  person_with_disability: boolean
  lgbtiqa: boolean
  atsi: boolean
  agree: boolean
  other_language: boolean
}

const genderButtons = [
  {
    value: "m",
    display: "Male"
  },
  {
    value: "f",
    display: "Female"
  },
  {
    value: "o",
    display: "Non-binary / Intersex / Own identity"
  },
  {
    value: "n",
    display: "Prefer not to say"
  }
]

export const PersonalDetailsForm = ({
  setPerson,
  resetPersonalDetails,
  person,
  volunteerOnly
}: PersonalDetailsFormProps) => {
  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    setError
  } = useForm<FormValues>({
    defaultValues: {
      full_name: person.full_name,
      agree: person.agree,
      email: person.email,
      address: person.address,
      suburb: person.suburb,
      postcode: person.postcode,
      mobile: person.mobile,
      gender: person.gender,
      date_of_birth: person.date_of_birth,
      lgbtiqa: person.lgbtiqa,
      atsi: person.atsi,
      other_language: person.other_language,
      person_with_disability: person.person_with_disability
    }
  })
  const [formError, setFormError] = React.useState("")
  const [scrollTrigger, setScrollTrigger] = React.useState(1)
  const [isLoading, setIsLoading] = React.useState(false)
  const topError =
    formError ||
    (Object.keys(errors).length > 0
      ? "Please fill in the required information below"
      : "")
  const [css] = useStyletron()
  const history = useHistory()
  const onSubmit = (values: FormValues) => {
    setFormError("")
    if (!values.agree) {
      setError("agree", "value", "You must agree to continue")
    } else {
      setIsLoading(true)
      const updated_person = {
        ...person,
        ...values
      }
      if (person.join) {
        if (sendJoinerData) {
          sendJoinerData(values)
        }
        sendSignup(updated_person)
          .then(response => {
            setPerson({
              ...updated_person,
              cost: undefined,
              person_id: response.person_id,
              people_id: response.people_id
            })
            sendJoinEvent(
              response.person_id,
              response.people_id,
              getConstant("joinPersonalDetailsEvent")
            )
            const employmentPage = isAutoconfirmState(
              getStateByPostcode(values.postcode)
            )
              ? "/employment-details"
              : "/manual-employment-details"
            history.push(employmentPage)
          })
          .catch(() => {
            setFormError(
              "We're having issues submitting your details, please check your details and try again later"
            )
            setPerson({
              ...person,
              people_id: undefined,
              person_id: undefined
            })
            setIsLoading(false)
            setScrollTrigger(value => value + 1)
          })
      } else {
        setPerson(updated_person)
        history.push("/volunteer-details")
      }
    }
  }
  return (
    <form id="personal-details" onSubmit={handleSubmit(onSubmit)}>
      <RunIdleTimer resetPersonalDetails={resetPersonalDetails} />
      <Layout
        heading="Personal Details"
        sectionStep={0}
        person={person}
        setPerson={setPerson}
        volunteerOnly={volunteerOnly}
        scrollTrigger={scrollTrigger}
      >
        {(person.join || person.volunteer) && (
          <>
            {topError && (
              <Cell span={[4, 8, 12]}>
                <Notification
                  overrides={{ Body: { style: { width: "auto" } } }}
                  kind="negative"
                >
                  {topError}
                </Notification>
              </Cell>
            )}
            <Cell span={[4, 8, 12]}>
              <FormControl
                error={errors.full_name && errors.full_name.message}
                label={"Full Name"}
                caption="Please enter your full name"
              >
                <Input
                  inputRef={register({
                    required: "Please enter your full name"
                  })}
                  overrides={{ InputContainer: { style: { width: "300px" } } }}
                  error={!!errors.full_name}
                  id="full_name"
                  name="full_name"
                />
              </FormControl>
            </Cell>
            <Cell span={[4, 8, 12]}>
              <FormControl
                error={errors.email && errors.email.message}
                label="Email"
              >
                <Input
                  inputRef={register({
                    required: "Please enter your email address",
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: "You must fill out a valid email address"
                    }
                  })}
                  overrides={{ InputContainer: { style: { width: "300px" } } }}
                  error={!!errors.email}
                  id="email"
                  name="email"
                  type="email"
                />
              </FormControl>
            </Cell>
            <Cell span={[4, 8, 12]}>
              <Controller
                as={WrappedAddressSearch}
                addressType="address"
                fieldLabel="Address"
                fieldName="address"
                setPostcode={(postcode: string) =>
                  setValue("postcode", postcode)
                }
                error={errors.address ? (errors.address as any) : undefined}
                setSuburb={(suburb: string | null) =>
                  setValue("suburb", suburb)
                }
                control={control}
                rules={{
                  required: "Please enter your address"
                }}
                valueName="inputValue"
                name="address"
              />
            </Cell>
            <Cell span={[4, 8, 12]}>
              <FormControl
                error={errors.suburb && errors.suburb.message}
                label="Suburb"
              >
                <Input
                  inputRef={register({
                    required: "Please enter your suburb"
                  })}
                  overrides={{
                    InputContainer: { style: { width: "250px" } },
                    Input: {}
                  }}
                  error={!!errors.suburb}
                  id="suburb"
                  name="suburb"
                />
              </FormControl>
            </Cell>
            <Cell span={[4, 8, 12]}>
              <FormControl
                error={errors.postcode && errors.postcode.message}
                label="Postcode"
              >
                <Input
                  inputRef={register({
                    required: "Please enter your postcode",
                    pattern: {
                      value: /^(0[289][0-9]{2}|[1-9][0-9]{3})$/,
                      message: "Must be a 4 digit number and a valid postcode"
                    }
                  })}
                  overrides={{
                    InputContainer: { style: { width: "100px" } },
                    Input: {
                      props: {
                        maxLength: "4"
                      }
                    }
                  }}
                  error={!!errors.postcode}
                  id="postcode"
                  name="postcode"
                  type="tel"
                />
              </FormControl>
            </Cell>
            <Cell span={[4, 8, 12]}>
              <FormControl
                error={errors.mobile && errors.mobile.message}
                label="Mobile"
              >
                <Input
                  inputRef={register({
                    required: "Please enter your mobile",
                    pattern: {
                      value: /^04[0-9]{8}$/,
                      message:
                        "Please enter a valid Australian Mobile number formatted as 0400000000"
                    },
                    minLength: {
                      value: 10,
                      message:
                        "Your phone number must have at least 10 digits (include area code)"
                    }
                  })}
                  overrides={{
                    InputContainer: { style: { width: "250px" } },
                    Input: {
                      props: {
                        maxLength: "12"
                      }
                    }
                  }}
                  error={!!errors.mobile}
                  id="mobile"
                  name="mobile"
                  type="tel"
                />
              </FormControl>
            </Cell>
            <Cell span={[4, 8, 12]}>
              <FormControl
                error={errors.gender && errors.gender.message}
                label="Gender"
              >
                <Controller
                  as={ButtonRadios}
                  name="gender"
                  control={control}
                  valueName={"currentValue"}
                  onChangeName={"updateFunction"}
                  mappings={genderButtons}
                />
              </FormControl>
            </Cell>
            <Cell span={[4, 8, 12]}>
              <FormControl
                label="Date of birth"
                error={
                  errors.date_of_birth &&
                  ((errors.date_of_birth as unknown) as FieldError).message
                }
                overrides={{
                  ControlContainer: { style: { maxWidth: "300px" } }
                }}
              >
                <Controller
                  as={Datepicker}
                  name="date_of_birth"
                  control={control}
                  onChange={([{ date }]) => {
                    if (!date) {
                      return null
                    }
                    if (Array.isArray(date)) {
                      return date
                    } else {
                      return [date]
                    }
                  }}
                  overrides={{ Input: { props: { id: "date_of_birth" } } }}
                  formatString={"dd/MM/yyyy"}
                  mask={"99/99/9999"}
                  placeholder={"DD/MM/YYYY"}
                  maxDate={new Date(new Date().getFullYear() - 11, 0)}
                  minDate={new Date("1910/01/01")}
                />
              </FormControl>
            </Cell>
            <Cell span={[4, 8, 12]}>
              <FormControl
                label={
                  <span>
                    Please select which of the following you identify as.{" "}
                    <em>Select all that apply.</em>
                  </span>
                }
              >
                <>
                  <Controller
                    control={control}
                    name="atsi"
                    as={Checkbox}
                    onChange={([e]) => e.currentTarget.checked}
                    valueName="checked"
                  >
                    Aboriginal and/or Torres Strait Islander
                  </Controller>
                  <Controller
                    control={control}
                    name="person_with_disability"
                    as={Checkbox}
                    onChange={([e]) => e.currentTarget.checked}
                    valueName="checked"
                  >
                    Person with a disability
                  </Controller>
                  <Controller
                    control={control}
                    name="lgbtiqa"
                    as={Checkbox}
                    onChange={([e]) => e.currentTarget.checked}
                    valueName="checked"
                  >
                    LGBTIQA+
                  </Controller>
                  <Controller
                    control={control}
                    name="other_language"
                    as={Checkbox}
                    onChange={([e]) => e.currentTarget.checked}
                    valueName="checked"
                  >
                    Speaking a language other than English at home
                  </Controller>
                </>
              </FormControl>
            </Cell>
            <Cell span={[4, 8, 12]}>
              <div
                className={css({
                  backgroundColor: "#eaeaea",
                  borderRadius: "5px",
                  padding: "20px"
                })}
              >
                <FormControl
                  label=""
                  error={errors.agree && errors.agree.message}
                >
                  <Controller
                    control={control}
                    name="agree"
                    defaultValue={false}
                    error={!!errors.agree}
                    as={Checkbox}
                    onChange={([e]) => e.currentTarget.checked}
                    valueName="checked"
                  >
                    I agree to the{" "}
                    <a
                      href="https://www.australianunions.org.au/privacy_policy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      privacy policy
                    </a>
                  </Controller>
                </FormControl>
                <ParagraphSmall>
                  The information you've provided will be kept in accordance
                  with our privacy policy, and shared only with your union.
                </ParagraphSmall>

                <Button
                  overrides={{ BaseButton: { style: { marginBottom: "0" } } }}
                  isLoading={isLoading}
                >
                  Next
                </Button>
              </div>
            </Cell>
          </>
        )}
      </Layout>
    </form>
  )
}
