import React from "react"
import { Layout } from "../components/Layout"
import { Cell } from "baseui/layout-grid"
import { useForm, Controller } from "react-hook-form"
import { FormControl } from "../components/FormControl"
import { Select } from "../components/Select"
import { Value } from "baseui/select"
import {
  State,
  isAutoconfirmState,
  getDeepestIndustry,
  shuffleArray
} from "../util"
import { Person } from "../App"
import { ParagraphMedium } from "baseui/typography"
import { Button } from "../components/Button"
import { UnionView } from "./UnionForm"
import { getConstant } from "../constants"

import {
  Industry,
  JobSearch,
  ManualIndustrySection
} from "./EmploymentDetailsForm"

interface CoverageLookupFormProps {
  setPerson: (person: Person | ((oldPerson: Person) => Person)) => void
  person: Person
  manual: boolean
  coverageState?: string | null
}

interface CoverageFormValues {
  covRegion: State
  job_title: string
  industry_1: Value | null
  industry_2: Value | null
  industry_3: Value | null
  industry_4: Value | null
  job_search: {
    label: string
    value: Value | null | undefined
  }
}

const stateOptions = [
  {
    id: "ACT",
    label: "Australian Capital Territory",
    disabled: !isAutoconfirmState("ACT")
  },
  {
    id: "NSW",
    label: "New South Wales",
    disabled: !isAutoconfirmState("NSW")
  },
  {
    id: "NT",
    label: "Northern Territory",
    disabled: !isAutoconfirmState("NT")
  },
  {
    id: "QLD",
    label: "Queensland",
    disabled: !isAutoconfirmState("QLD")
  },
  {
    id: "SA",
    label: "South Australia",
    disabled: !isAutoconfirmState("SA")
  },
  {
    id: "TAS",
    label: "Tasmania",
    disabled: !isAutoconfirmState("TAS")
  },
  {
    id: "VIC",
    label: "Victoria",
    disabled: !isAutoconfirmState("VIC")
  },
  {
    id: "WA",
    label: "Western Australia",
    disabled: !isAutoconfirmState("WA")
  }
]

interface StateSelectProps {
  value?: string
  onChange: (option: string) => void
}

export const StateSelect = (props: StateSelectProps) => {
  let value: Value = []
  stateOptions.forEach(option => {
    if (option.id === props.value) {
      value = [option]
    }
  })
  return (
    <FormControl
      label="State"
      overrides={{ ControlContainer: { style: { maxWidth: "300px" } } }}
    >
      <Select
        overrides={{
          Input: {
            props: {
              id: `covRegion`,
              name: `covRegion`
            }
          },
          Dropdown: {
            style: {
              maxHeight: "200px"
            }
          }
        }}
        placeholder={"Please Choose"}
        options={stateOptions}
        onChange={value => props.onChange(value.value[0].id as string)}
        value={value}
        backspaceRemoves={false}
        clearable={false}
        searchable={true}
      />
    </FormControl>
  )
}

interface UnionViewSelectorProps {
  deepestIndustry: Industry | null
}
const UnionViewSelector = ({ deepestIndustry }: UnionViewSelectorProps) => {
  let Selector: React.ReactElement | null = null

  const isContestedMessage = (
    <>
      <ParagraphMedium>
        No pre-determined coverage is currently available for this job.
      </ParagraphMedium>
    </>
  )
  if (deepestIndustry && deepestIndustry.coverage) {
    // Do not display branches for disputed / complex coverage situations
    if (
      deepestIndustry.coverage.some(union => {
        return union.is_contested
      })
    ) {
      Selector = isContestedMessage
    } else if (deepestIndustry.coverage.length > 0) {
      const leadText =
        deepestIndustry.coverage.length === 1
          ? "The Union that provides coverage for this job is:"
          : `There are ${deepestIndustry.coverage.length} Unions that provide coverage for this job:`

      // Randomise the list of unions
      if (getConstant("environment") !== "storybook") {
        shuffleArray(deepestIndustry.coverage)
      }

      Selector = (
        <>
          <ParagraphMedium>{leadText}</ParagraphMedium>
          {deepestIndustry.coverage.map(cov => (
            <UnionView key={cov.branch_code} coverage={cov} />
          ))}
        </>
      )
    }
  }

  return (
    <>
      <Cell span={[4, 8, 12]}>{Selector}</Cell>
    </>
  )
}

const defaultFormValues = {
  job_search: undefined,
  industry_1: null,
  industry_2: null,
  industry_3: null,
  industry_4: null,
  covRegion: null
}

export const CoverageLookupForm = ({
  setPerson,
  person,
  manual,
  coverageState
}: CoverageLookupFormProps) => {
  const { handleSubmit, setValue, watch, control, reset } = useForm<
    CoverageFormValues
  >({
    defaultValues: defaultFormValues
  })

  const covRegion = watch("covRegion")
  const industry_1 = watch("industry_1")
  const industry_2 = watch("industry_2")
  const industry_3 = watch("industry_3")
  const industry_4 = watch("industry_4")
  const job_search = watch("job_search")
  const deepestIndustry = getDeepestIndustry(
    industry_1,
    industry_2,
    industry_3,
    industry_4,
    job_search
  )

  const onSubmit = () => {
    reset()
  }

  const buttonText = "Reset"
  const button = (
    <Button overrides={{ BaseButton: { style: { marginBottom: "0" } } }}>
      {buttonText}
    </Button>
  )
  return (
    <form id="coverage" onSubmit={handleSubmit(onSubmit)}>
      <Layout
        heading="Australian Unions Coverage Lookup Tool"
        sectionStep={1}
        person={person}
        setPerson={setPerson}
      >
        <Cell span={[4, 8, 12]}>
          <Controller as={StateSelect} control={control} name="covRegion" />
        </Cell>
        <Cell span={[4, 8, 12]}>
          {!manual && covRegion && (
            <Controller
              as={JobSearch}
              disabled={false}
              state={covRegion}
              control={control}
              name="job_search"
            />
          )}
        </Cell>
        {manual && covRegion && (
          <ManualIndustrySection
            disabled={false}
            industry_1={industry_1}
            industry_2={industry_2}
            industry_3={industry_3}
            control={control}
            state={covRegion}
            setValue={setValue}
          />
        )}
        {deepestIndustry && (
          <UnionViewSelector deepestIndustry={deepestIndustry} />
        )}
        <Cell span={[4, 8, 12]}>{button}</Cell>
      </Layout>
    </form>
  )
}
