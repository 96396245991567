import React from "react"
import { Select as BaseSelect, SelectProps } from "baseui/select"
import withOverrides from "../overrides"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretDown } from "@fortawesome/free-solid-svg-icons"
import { palette } from "../configurables"
import { useStyletron } from "baseui"

const SelectIcon = () => {
  const [css] = useStyletron()
  return (
    <FontAwesomeIcon
      className={css({
        color: palette.tertiary,
        fontSize: "24px"
      })}
      icon={faCaretDown}
    />
  )
}

const globalOverrides: SelectProps["overrides"] = {
  Popover: {
    style: {
      zIndex: 100
    }
  },
  ControlContainer: {
    style: {
      borderRightColor: palette.secondary,
      borderLeftColor: palette.secondary,
      borderTopColor: palette.secondary,
      borderBottomColor: palette.secondary,
      borderRightStyle: "solid",
      borderLeftStyle: "solid",
      borderTopStyle: "solid",
      borderBottomStyle: "solid",
      borderRightWidth: "2px",
      borderLeftWidth: "2px",
      borderTopWidth: "2px",
      borderBottomWidth: "2px",
      borderTopLeftRadius: "5px",
      borderTopRightRadius: "5px",
      borderBottomRightRadius: "5px",
      borderBottomLeftRadius: "5px"
    }
  },
  SelectArrow: {
    component: SelectIcon
  },
  IconsContainer: {
    style: {
      paddingLeft: "18px",
      paddingRight: "18px",
      borderLeftStyle: "solid",
      borderLeftWidth: "2px",
      borderLeftColor: palette.secondary,
      ":hover": {
        cursor: "pointer"
      }
    }
  }
}

export const Select = withOverrides<SelectProps>(BaseSelect, globalOverrides)

const searchGlobalOverrides: SelectProps["overrides"] = {
  Popover: {
    style: {
      zIndex: 100
    }
  },
  ControlContainer: {
    style: {
      borderRightColor: palette.secondary,
      borderLeftColor: palette.secondary,
      borderTopColor: palette.secondary,
      borderBottomColor: palette.secondary,
      borderRightStyle: "solid",
      borderLeftStyle: "solid",
      borderTopStyle: "solid",
      borderBottomStyle: "solid",
      borderRightWidth: "2px",
      borderLeftWidth: "2px",
      borderTopWidth: "2px",
      borderBottomWidth: "2px",
      borderTopLeftRadius: "5px",
      borderTopRightRadius: "5px",
      borderBottomRightRadius: "5px",
      borderBottomLeftRadius: "5px"
    }
  }
}

export const SearchSelect = withOverrides<SelectProps>(
  BaseSelect,
  searchGlobalOverrides
)
