import { Client as Styletron } from "styletron-engine-atomic"
import { createTheme, lightThemePrimitives } from "baseui"
import { getConstant } from "./constants"

export const engine = new Styletron({ prefix: "join-" })

export const poppins = engine.renderFontFace({
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  src: `local('Poppins Regular'), local('Poppins-Regular'),
  url('${getConstant(
    "publicURL"
  )}/fonts/poppins-v5-latin_latin-ext-regular.woff2') format('woff2'),
  url('${getConstant(
    "publicURL"
  )}/fonts/poppins-v5-latin_latin-ext-regular.woff') format('woff'),
  url('${getConstant(
    "publicURL"
  )}/fonts/poppins-v5-latin_latin-ext-regular.ttf') format('truetype')`
})
export const poppinsBold = engine.renderFontFace({
  fontFamily: "Poppins",
  fontStyle: "bold",
  fontWeight: 700,
  src: `local('Poppins Bold'), local('Poppins-Bold'),
  url('${getConstant(
    "publicURL"
  )}/fonts/poppins-v5-latin_latin-ext-700.woff2') format('woff2'),
  url('${getConstant(
    "publicURL"
  )}/fonts/poppins-v5-latin_latin-ext-700.woff') format('woff'),
  url('${getConstant(
    "publicURL"
  )}/fonts/poppins-v5-latin_latin-ext-700.ttf') format('truetype')`
})

const white = "#FFFFFF"
const black = "#000000"
const blazeOrange = "#FF6B00"
const dustyGray = "#333333"
const silver = "#CCCCCC"

export const palette = {
  white: white,
  black: black,
  primary: blazeOrange,
  secondary: dustyGray,
  tertiary: silver
}

const dustyGrayTypography = {
  color: dustyGray
}

const headingTypography = {
  fontWeight: "bold",
  fontSize: "20px",
  color: palette.primary
}

export const theme = createTheme(
  {
    ...lightThemePrimitives,
    primaryFontFamily: "Poppins"
  },
  {
    colors: {
      buttonSecondaryFill: white,
      buttonSecondaryText: black,
      buttonSecondarySelectedFill: blazeOrange,
      buttonSecondarySelectedText: white,
      buttonPrimaryFill: blazeOrange,
      inputFill: white,
      tickFill: white,
      tickFillActive: silver,
      tickFillHover: silver,
      tickFillSelected: blazeOrange,
      tickFillSelectedHover: blazeOrange,
      tickFillSelectedHoverActive: blazeOrange,
      inputBorder: dustyGray
    },
    borders: {
      inputBorderRadius: "5px"
    },
    typography: {
      ParagraphXSmall: dustyGrayTypography,
      ParagraphSmall: dustyGrayTypography,
      ParagraphMedium: dustyGrayTypography,
      ParagraphLarge: dustyGrayTypography,
      LabelXSmall: dustyGrayTypography,
      LabelSmall: dustyGrayTypography,
      LabelMedium: dustyGrayTypography,
      LabelLarge: dustyGrayTypography,
      font950: headingTypography
    }
  }
)
