import {
  Datepicker as BaseDatepicker,
  DatepickerProps,
  DatepickerOverrides,
  SharedStylePropsT
} from "baseui/datepicker"
import { globalOverrides as inputOverrides } from "./Input"
import withOverrides from "../overrides"

export const globalOverrides: DatepickerOverrides<SharedStylePropsT> = {
  Input: {
    props: {
      overrides: inputOverrides
    }
  }
}

export const Datepicker = withOverrides<DatepickerProps>(
  BaseDatepicker,
  globalOverrides
)
