import React from "react"
import { Link } from "react-router-dom"
import { Layout } from "../components/Layout"
import { Cell } from "baseui/layout-grid"
import { ParagraphLarge } from "baseui/typography"
import { Person } from "../App"
import { findBranch } from "../util"

export const ExpiredPage = ({
  person,
  setPerson,
  volunteerOnly
}: {
  person: Person
  setPerson: (person: Person | ((oldPerson: Person) => Person)) => void
  volunteerOnly: boolean
}) => {
  const branch = findBranch(person)
  let step = 1
  if (person.join) {
    if (branch && branch.payment_mode === "bypass") {
      step = step + 1
    } else {
      step = step + 2
    }
  }
  if (person.volunteer && person.join) {
    step++
  }
  let expiredMessage: JSX.Element | null = null
  // Person has a filledOutEmployerDetails attribute to check if p2 has been submitted
  if (person.filledOutEmployerDetails) {
    expiredMessage = (
      <>
        <ParagraphLarge>
          Your session has timed out, but we've saved the details you entered.
          We'll be in touch shortly to complete the joining process.
        </ParagraphLarge>
        <ParagraphLarge>
          If you want to re-enter or change your details, please{" "}
          <Link to="/">start again</Link>.
        </ParagraphLarge>
      </>
    )
  } else {
    expiredMessage = (
      <>
        <ParagraphLarge>
          Your session has timed out. Please{" "}
          <Link to="/">re-enter your details</Link> to find and join your union.
        </ParagraphLarge>
      </>
    )
  }
  return (
    <Layout
      heading="Your session has timed out."
      sectionStep={step}
      person={person}
      setPerson={setPerson}
    >
      <Cell span={[4, 8, 12]}>{expiredMessage}</Cell>
    </Layout>
  )
}
