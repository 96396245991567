import {
  Input as BaseInput,
  MaskedInput as BaseMaskedInput,
  MaskedInputProps,
  InputProps,
  InputOverrides
} from "baseui/input"
import withOverrides from "../overrides"
import { palette } from "../configurables"

export const globalOverrides: InputOverrides = {
  InputContainer: {
    style: {
      borderRightColor: palette.secondary,
      borderLeftColor: palette.secondary,
      borderTopColor: palette.secondary,
      borderBottomColor: palette.secondary,
      borderRightStyle: "solid",
      borderLeftStyle: "solid",
      borderTopStyle: "solid",
      borderBottomStyle: "solid",
      borderRightWidth: "2px",
      borderLeftWidth: "2px",
      borderTopWidth: "2px",
      borderBottomWidth: "2px",
      borderTopLeftRadius: "5px",
      borderTopRightRadius: "5px",
      borderBottomRightRadius: "5px",
      borderBottomLeftRadius: "5px"
    }
  }
}

export const Input = withOverrides<InputProps>(BaseInput, globalOverrides)
export const MaskedInput = withOverrides<MaskedInputProps>(
  BaseMaskedInput,
  globalOverrides
)
