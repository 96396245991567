import {
  Checkbox as BaseCheckbox,
  CheckboxProps,
  CheckboxOverrides
} from "baseui/checkbox"
import withOverrides from "../overrides"

const globalOverrides: CheckboxOverrides = {
  Checkmark: {
    style: {
      height: "27px",
      width: "27px",
      borderTopWidth: "2px",
      borderRightWidth: "2px",
      borderLeftWidth: "2px",
      borderBottomWidth: "2px"
    }
  },
  Root: {
    style: {
      marginTop: "12px",
      alignItems: "center"
    }
  }
}

export const Checkbox = withOverrides<CheckboxProps>(
  BaseCheckbox,
  globalOverrides
)
