import React from "react"
import { Person } from "../App"
import { useHistory } from "react-router-dom"
import { sendNetsuiteStatus } from "../api"

const base_url =
  "https://4854968.extforms.netsuite.com/app/site/crm/externalleadpage.nl/compid.4854968/.f?formid=46&h=AAFdikaITP7qQ-aPVLZBS3v5hK314BqziWPFSAfloW93rMs7jz0"

const person_map = {
  email: "email",
  city: "suburb",
  zipcode: "postcode",
  phone: "mobile",
  custentity1: "employer",
  person_id: "person_id",
  people_id: "people_id",
  branch_id: "netsuite_branch_id"
}

const gender_map = {
  f: 1,
  m: 2,
  o: 3,
  n: 4
}

export const NetsuiteIFrame = (person: Person) => {
  const { push } = useHistory()
  React.useEffect(() => {
    const messageListener = (event: MessageEvent) => {
      let payload = event.data
      let branch_id = person.netsuite_branch_id || ""
      if (payload === "SUBMISSION_SUCCESSFUL") {
        sendNetsuiteStatus(person, branch_id).then(() => {
          push("/complete")
        })
      }
    }
    window.addEventListener("message", messageListener)
  }, [person, push])

  // Need to split up the name as per other places sigh
  var url = base_url

  var name_split = person.full_name?.split(" ")

  var firstname = name_split?.[0]
  var lastname = name_split?.slice(-1)

  if (firstname !== undefined) {
    url = `${url}&firstname=${firstname}`
  }

  if (lastname !== undefined && name_split?.length !== 1) {
    url = `${url}&lastname=${lastname}`
  }

  // Set address - also includes suburb etc.
  var address = person.address?.label

  if (address !== undefined) {
    url = `${url}&address1=${address}`
  }

  // Set date of birth
  var dob = person.date_of_birth
  if (dob !== undefined) {
    var date = dob[0].getDate()
    var month = dob[0].getMonth() + 1 // god
    var year = dob[0].getFullYear()
    url = `${url}&custentity_kl_member_dob=${date}/${month}/${year}`
  }

  var gender = person.gender
  if (gender !== undefined) {
    url = `${url}&custentity_kl_member_gender=${gender_map[gender]}`
  }

  // IDK why this works but the object is an object but the type is string
  // in the backend, so changing it to a Real String and then back to JSON works
  var employer_address = JSON.stringify(person.employer_address)
  if (employer_address !== undefined && employer_address !== null) {
    var label = JSON.parse(employer_address).label
    url = `${url}&custentity_kl_employee_worksite=${label}`
  }

  var job_title = person.job_search?.label
  if (job_title !== undefined) {
    url = `${url}&title=${job_title}`
  }

  for (const [key, value] of Object.entries(person_map)) {
    var key_string = value as keyof Person
    if (person[key_string] !== undefined)
      url = `${url}&${key}=${person[key_string]}`
  }
  return (
    <iframe
      src={url}
      title="netsuite"
      scrolling="no"
      frameBorder="0"
      style={{
        overflow: "auto",
        display: "block",
        border: "none",
        position: "absolute",
        height: "4200px",
        width: "100%",
        margin: "25px"
      }}
    ></iframe>
  )
}
