import axios from "axios"
import { getConstant } from "./constants"
import {
  getStateByPostcode,
  getQueryVariable,
  State,
  isAutoconfirmState
} from "./util"
import { FormValues } from "./pages/VolunteerDetailsForm"
import { Person } from "./App"
import { Industry } from "./pages/EmploymentDetailsForm"
import { getDeepestIndustry } from "./util"
import * as Sentry from "@sentry/browser"
function getData(url = "", headers = {}) {
  const response = axios
    .get(url, {
      headers: {
        "Content-Type": "application/json",
        ...headers
      },
      withCredentials: false
    })
    .then(successResponse => {
      return successResponse.data
    })
    .catch(error => {
      Sentry.captureException(error)
      throw error
    })
  return response
}

export function postData(url = "", headers = {}, data = {}) {
  const response = axios
    .post(url, data, {
      headers: {
        "Content-Type": "application/json",
        ...headers
      },
      withCredentials: false
    })
    .then(successResponse => {
      return successResponse.data
    })
    .catch(error => {
      Sentry.captureException(error)
      throw error
    })
  return response
}
function putData(url = "", headers = {}, data = {}) {
  const response = axios
    .put(url, data, {
      headers: {
        "Content-Type": "application/json",
        ...headers
      },
      withCredentials: false
    })
    .then(successResponse => {
      return successResponse.data
    })
    .catch(error => {
      Sentry.captureException(error)
      throw error
    })
  return response
}

export function updateSignupAccount(
  person: Person,
  branch_id?: string
): Promise<null> {
  const url = `${getConstant("joinApi")}/join/v2/signup`
  const data = {
    person_id: person.person_id,
    people_id: person.people_id,
    branch_id: branch_id || null,
    account_number: person.accountNo,
    bsb: person.bsb,
    account_name: person.accountName,
    amount: person.cost,
    charge_fz: getConstant("environment") !== "test"
  }
  return putData(url, {}, data)
}

export function updateSignup(
  person: Person,
  branch_id: string,
  stored_cc: boolean = false
): Promise<null> {
  const url = `${getConstant("joinApi")}/join/v2/signup`
  const data: any = {
    person_id: person.person_id,
    people_id: person.people_id,
    branch_id: branch_id || null
  }
  if (stored_cc) {
    data["stored_cc"] = true
  }
  return putData(url, {}, data)
}

export function sendBypassStatus(
  person: Person,
  branch_id: string
): Promise<null> {
  const url = `${getConstant("joinApi")}/join/v2/signup_bypass`
  const data = {
    person_id: person.person_id,
    people_id: person.people_id,
    branch_id: branch_id
  }
  return postData(url, {}, data)
}

export function sendNetsuiteStatus(
  person: Person,
  branch_id: string
): Promise<null> {
  if (person.person_id && person.people_id) {
    sendJoinEvent(
      person.person_id,
      person.people_id,
      getConstant("joinViaExternalAttempt")
    )
  }
  const url = `${getConstant("joinApi")}/join/v2/signup_netsuite`
  const data = {
    person_id: person.person_id,
    people_id: person.people_id,
    branch_id: branch_id
  }
  return postData(url, {}, data)
}

export function sendToken(
  postmessageEvent: any,
  person_id: string,
  people_id: string
): Promise<null> {
  const url = `${getConstant("joinApi")}/join/v2/store_token`
  return postData(
    url,
    {},
    {
      postmessage: postmessageEvent,
      person_id: person_id,
      people_id: people_id,
      join_form: true
    }
  )
}
export interface BranchContactDetails {
  address_street1?: string
  address_street2?: string
  address_suburb?: string
  address_state?: string
  address_postcode?: string
  phone1?: string
  branch_email?: string
}
export function getBranchDetails(
  branch_id: string
): Promise<BranchContactDetails> {
  const url = `${getConstant(
    "joinApi"
  )}/join/v2/branch_contact_details/${branch_id}`
  return getData(url)
}
export function sendSignup(person: Person): Promise<any> {
  const url = `${getConstant("joinApi")}/join/v2/signup`
  const date = person.date_of_birth && person.date_of_birth[0]

  const {
    employer,
    duties,
    gender,
    suburb,
    postcode,
    email,
    mobile,
    hours_per_week,
    employer_postcode,
    employer_suburb,
    pay,
    job_type,
    person_id,
    people_id
  } = person
  const industry = getDeepestIndustry(
    person.industry_1,
    person.industry_2,
    person.industry_3,
    person.industry_4,
    person.job_search
  )
  const search = window.location.search
  const body = {
    name: person.full_name,
    mobile,
    email,
    address: person && person.address ? person.address.label : null,
    suburb,
    postcode,
    state: person.postcode ? getStateByPostcode(person.postcode) : null,
    gender,
    employed: "yes",
    job_title:
      person.job_title || (person.job_search && person.job_search.label),
    duties,
    employer,
    dob:
      date && `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
    source: person.source || getConstant("formVersion") || "join3",
    is_joiner: person.duties && person.employer ? true : false,
    industry_id: industry && industry.industry_id,
    utm_source:
      person.utm_source || getQueryVariable("utm_source", search) || null,
    utm_medium:
      person.utm_medium || getQueryVariable("utm_medium", search) || null,
    utm_campaign:
      person.utm_campaign || getQueryVariable("utm_campaign", search) || null,
    utm_content:
      person.utm_content || getQueryVariable("utm_content", search) || null,
    referer: person.referer || (document && document.referrer) || "direct",
    other_language: !!person.other_language,
    lgbtiqa: !!person.lgbtiqa,
    atsi: !!person.atsi,
    person_with_disability: !!person.person_with_disability,
    send_email: false,
    hours_per_week,
    employer_postcode,
    employer_suburb,
    pay,
    job_type,
    person_id: person_id || null,
    people_id: people_id || null,
    additional: person.additional || null
  }
  if (person_id) {
    return putData(url, {}, body)
  } else {
    return postData(url, {}, body)
  }
}

export function sendVolunteerSignup(
  person: Person,
  values: FormValues
): Promise<any> {
  const url = `${getConstant("joinApi")}/join/v2/volunteer`
  const to_post = {
    name: person.full_name,
    mobile: person.mobile,
    email: person.email,
    address: person.address && person.address.label,
    suburb: person.suburb,
    postcode: person.postcode,
    duties: person.duties,
    state: person.postcode ? getStateByPostcode(person.postcode) : null,
    job_title:
      person.job_title || (person.job_search && person.job_search.label),
    employer: person.employer,
    campaign_news: values.campaignNews,
    leaflets_action_talking: values.leafletsActionTalking
  }
  return postData(url, {}, to_post)
}
interface IndustriesResponse {
  industries?: Industry[]
  industry?: Industry
}

export function getIndustries(
  parent: string | null = null,
  state: State | null | undefined
): Promise<IndustriesResponse> {
  const url = `${getConstant("joinApi")}/join/v2/industries${
    parent ? `/${parent}` : ""
  }?state=${state}&bleach=false&filter_coverage=${isAutoconfirmState(state)}`
  return getData(url)
}

export function getPaymentURL(
  person_id: string,
  people_id: string,
  branch_id?: string
): Promise<{ url: string }> {
  let reference = person_id
  let url = `${getConstant("joinApi")}/join/v2/`
  if (branch_id) {
    reference += `|${branch_id}`
    url = `${url}payment_request?reference=${reference}&people_id=${people_id}`
  } else {
    url = `${url}token_request?reference=${reference}&people_id=${people_id}`
  }

  return getData(url)
}

interface SearchIndustry extends Industry {
  display_name: string
}
interface IndustryResults {
  results: SearchIndustry[]
}
export function searchIndustries(
  query: string,
  state: string
): Promise<IndustryResults> {
  const url = `${getConstant(
    "joinApi"
  )}/join/v2/industries/search?state=${state}&bleach=false&query=${query}`
  return getData(url)
}

export function sendJoinEvent(
  person_id: string,
  people_id: string,
  action: string
): Promise<null> {
  const url = `${getConstant("joinApi")}/join/v2/join_event`
  const data = {
    person_id: person_id,
    people_id: people_id,
    process: getConstant("joinEventProcess"),
    event: action
  }
  return postData(url, {}, data)
}

export function shareJoinForm(
  person_id: string | undefined,
  people_id: string | undefined,
  recipients: Array<
    | { emailAddress: string; mobileNumber: string }
    | { emailAddress?: string; mobileNumber: string }
    | { emailAddress: string; mobileNumber?: string }
  >
): Promise<any> {
  const url = `${getConstant("joinApi")}/join/v2/share`
  const emailAddresses = recipients
    ?.filter(object => !!object.emailAddress)
    .map(object => object.emailAddress)
  const mobileNumbers = recipients
    ?.filter(object => !!object.mobileNumber)
    .map(object => object.mobileNumber)
  const uniqueEmailAddresses = [...new Set(emailAddresses)]
  const uniqueMobileNumbers = [...new Set(mobileNumbers)]
  const data = {
    person_id: person_id,
    people_id: people_id,
    email_addresses: uniqueEmailAddresses,
    mobile_numbers: uniqueMobileNumbers
  }
  Sentry.captureMessage(`Sharing from ${person_id} at ${new Date()}`)
  return postData(url, {}, data)
}
