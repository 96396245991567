import React from "react"
import { Layout } from "../components/Layout"
import { Cell } from "baseui/layout-grid"
import { ParagraphLarge } from "baseui/typography"
import { Person } from "../App"

const ShareCompleteMessage = () => (
  <>
    <ParagraphLarge>All done!</ParagraphLarge>
    <ParagraphLarge>
      Head on over to the{" "}
      <a href="https://shop.australianunions.org.au/">
        Australian Unions Store
      </a>{" "}
      to enjoy 10% off any purchase using the code NEWMEMBER10.
    </ParagraphLarge>
    <ParagraphLarge>
      Stay in touch with all the latest campaigns from Australian Unions on{" "}
      <a href="https://www.facebook.com/AustralianUnions/">Facebook,</a>{" "}
      <a href="https://www.instagram.com/ausunions/">Instagram</a> and on our{" "}
      <a href="https://www.australianunions.org.au/">website.</a>
    </ParagraphLarge>
  </>
)
export const ShareComplete = ({
  person
}: {
  person: Person
  resetPersonalDetails: (completeReset?: boolean) => void
}) => {
  return (
    <Layout
      heading="Thank you for growing the movement!"
      person={person}
      sectionStep={6}
      disableBackwardNavigation={true}
    >
      <Cell span={[4, 8, 12]}>
        <ShareCompleteMessage />
      </Cell>
    </Layout>
  )
}
