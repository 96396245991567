import React, { useEffect } from "react"
import { Layout } from "../components/Layout"
import { Cell } from "baseui/layout-grid"
import { Button } from "../components/Button"
import { useForm, Controller, Control, FieldError } from "react-hook-form"
import { FormControl } from "../components/FormControl"
import { useHistory } from "react-router-dom"
import { RadioGroup } from "baseui/radio"
import { Radio } from "../components/Radio"
import { RunIdleTimer } from "../components/RunIdleTimer"
import { ParagraphMedium } from "baseui/typography"
import { getDeepestIndustry, getBranchId, shuffleArray } from "../util"
import { Person } from "../App"
import { Notification } from "baseui/notification"
import { Industry, Coverage } from "./EmploymentDetailsForm"
import { updateSignup, sendBypassStatus, sendJoinEvent } from "../api"
import { useStyletron } from "baseui"
import { analytics } from "../analytics"
import { getConstant } from "../constants"
interface UnionFormProps {
  setPerson: (person: Person | ((oldPerson: Person) => Person)) => void
  person: Person
  resetPersonalDetails: (completeReset?: boolean) => void
}

interface FormValues {
  branch_id: string | null
}

export interface UnionViewProps {
  coverage: Coverage
}
export const UnionView = ({ coverage }: UnionViewProps) => {
  return (
    <>
      <ParagraphMedium>
        <strong>{coverage.full_name}</strong>
      </ParagraphMedium>
      {coverage.description && (
        <ParagraphMedium>{coverage.description}</ParagraphMedium>
      )}
    </>
  )
}

interface UnionSelectorProps {
  deepestIndustry: Industry | null
  control: Control
  error: FieldError | undefined
  isLoading: boolean
  setPerson: (person: Person | ((oldPerson: Person) => Person)) => void
  person: Person
  value: string | null
  resetPersonalDetails: (completeReset?: boolean) => void
}
const UnionSelector = ({
  deepestIndustry,
  control,
  error,
  isLoading,
  setPerson,
  person,
  value,
  resetPersonalDetails
}: UnionSelectorProps) => {
  const [css] = useStyletron()
  let Selector: React.ReactElement | null = null
  let webLink = ""
  let selectedUnionName = ""
  let netsuite = false
  let netsuite_branch_id = ""
  const notAutoConfirmMessage = (
    <>
      <ParagraphMedium>
        We need to do a bit more work to find out which union is right for you.
        We'll get in touch shortly.
      </ParagraphMedium>
      <ParagraphMedium>
        To complete your membership application, please now enter your payment
        details. You will only be charged once your union has been confirmed.
      </ParagraphMedium>
    </>
  )
  if (deepestIndustry && deepestIndustry.coverage) {
    // Randomise the list of unions
    if (getConstant("environment") !== "storybook") {
      shuffleArray(deepestIndustry.coverage)
    }

    // Do not display branches for disputed / complex coverage situations
    if (
      deepestIndustry.coverage.some(union => {
        return union.is_contested
      })
    ) {
      Selector = notAutoConfirmMessage
    } else if (deepestIndustry.coverage.length > 1) {
      Selector = (
        <FormControl
          error={error && error.message}
          label="Based on the information you provided, there are multiple unions that cover your job. Please select one from the options below"
        >
          <Controller
            as={RadioGroup}
            error={!!error}
            rules={{ required: "Please select a union" }}
            control={control}
            name="branch_id"
          >
            {/* Generate each radio button*/}
            {deepestIndustry.coverage.map((union, index) => {
              // Does not affect the radio button, but if an opt-out union is selected
              // we need to get the website so we can display it in place of the next button
              if (
                value &&
                value === union.branch_id &&
                ["bypass", "netsuite"].includes(union.payment_mode)
              ) {
                webLink = union.website
                selectedUnionName = union.short_name
                netsuite = union.payment_mode === "netsuite"
                netsuite_branch_id = union.branch_id
              }
              const overrides =
                index === 0 ? {} : { style: { marginTop: "24px" } }
              return (
                <Radio
                  key={union.branch_id}
                  overrides={{
                    Root: overrides
                  }}
                  description={union.description || ""}
                  value={union.branch_id}
                >
                  {union.full_name}
                </Radio>
              )
            })}
          </Controller>
        </FormControl>
      )
    } else if (deepestIndustry.coverage.length === 1) {
      selectedUnionName = deepestIndustry.coverage[0].short_name
      if (deepestIndustry.coverage[0].payment_mode === "bypass") {
        webLink = deepestIndustry.coverage[0].website
        value = deepestIndustry.coverage[0].branch_id
      } else if (deepestIndustry.coverage[0].payment_mode === "netsuite") {
        netsuite = true
        netsuite_branch_id = deepestIndustry.coverage[0].branch_id
      }
      Selector = (
        <>
          <ParagraphMedium>
            The Union that can represent you is:
          </ParagraphMedium>
          <ParagraphMedium>
            <strong>{deepestIndustry.coverage[0].full_name}</strong>
          </ParagraphMedium>
          {deepestIndustry.coverage[0].description && (
            <ParagraphMedium>
              {deepestIndustry.coverage[0].description}
            </ParagraphMedium>
          )}
        </>
      )
    }
  } else {
    Selector = notAutoConfirmMessage
  }
  React.useEffect(() => {
    if (selectedUnionName) {
      analytics.track(
        "union-selected",
        {
          branch_name: selectedUnionName
        },
        {
          plugins: {
            fbpixel: false
          }
        }
      )
    }
  }, [selectedUnionName])
  const { push } = useHistory()
  if (netsuite) {
    person.netsuite_branch_id = netsuite_branch_id
  }
  if (webLink) {
    return (
      <>
        <Cell span={[4, 8, 12]}>{Selector}</Cell>
        <Cell span={[4, 8, 12]}>
          <div
            className={css({
              backgroundColor: "#eaeaea",
              borderRadius: "5px",
              marginTop: "16px",
              padding: "20px"
            })}
          >
            <a
              href={webLink}
              onClick={e => {
                analytics.track(
                  "union-link-clicked",
                  {
                    branch_name: selectedUnionName,
                    website: webLink
                  },
                  {
                    plugins: {
                      fbpixel: false
                    }
                  }
                )
                if (person.person_id && person.people_id) {
                  sendJoinEvent(
                    person.person_id,
                    person.people_id,
                    getConstant("joinCoverageConfirmEvent")
                  )
                  sendJoinEvent(
                    person.person_id,
                    person.people_id,
                    getConstant("joinBypassLinkEvent")
                  )
                }
                if (value) {
                  sendBypassStatus(person, value).then(() => {
                    if (person.volunteer) {
                      push("/volunteer-details")
                    } else {
                      push("/complete")
                      resetPersonalDetails()
                    }
                  })
                }
                if (getConstant("environment") === "test") {
                  // Don't want new windows opening during testing
                  // but we DO want to test sending bypass status
                  e.preventDefault()
                }
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ParagraphMedium>
                Please click here to go to the {selectedUnionName} site to
                complete your sign up.
              </ParagraphMedium>
            </a>
          </div>
        </Cell>
      </>
    )
  } else {
    return (
      <>
        <Cell span={[4, 8, 12]}>{Selector}</Cell>
        <Cell span={[4, 8, 12]}>
          <Button isLoading={isLoading}>Next</Button>
        </Cell>
      </>
    )
  }
}

export const UnionForm = ({
  setPerson,
  person,
  resetPersonalDetails
}: UnionFormProps) => {
  const deepestIndustry = getDeepestIndustry(
    person.industry_1,
    person.industry_2,
    person.industry_3,
    person.industry_4,
    person.job_search
  )
  const { handleSubmit, control, errors, watch } = useForm<FormValues>({
    defaultValues: {
      branch_id: person.branch_id
    }
  })
  const { push } = useHistory()

  useEffect(() => {
    if (!person.people_id || !person.person_id) {
      push("/")
    }
  }, [person.people_id, person.person_id, push])
  useEffect(() => {
    // when it mounts, null the Branch ID so that there isn't an issue of someone going back then forward
    // and being signed up to the wrong branch
    setPerson((oldPerson: Person) => {
      return {
        ...oldPerson,
        branch_id: null,
        netsuite_branch_id: null
      }
    })
  }, [setPerson])
  const [formError, setFormError] = React.useState("")
  const [isLoading, setIsLoading] = React.useState(false)
  const [scrollTrigger, setScrollTrigger] = React.useState(1)
  const topError =
    formError ||
    (Object.keys(errors).length > 0
      ? "Please fill in the required information below"
      : "")
  const onSubmit = (values: FormValues) => {
    setFormError("")
    setIsLoading(true)
    const branch_id = getBranchId(values.branch_id, deepestIndustry)
    const updated_person = {
      ...person,
      ...values
    }
    setPerson(updated_person)

    if (branch_id) {
      updateSignup(person, branch_id)
        .then(() => {
          if (person.person_id && person.people_id) {
            sendJoinEvent(
              person.person_id,
              person.people_id,
              getConstant("joinCoverageConfirmEvent")
            )
          }
          if (person.netsuite_branch_id) {
            push("/netsuite-form")
          } else {
            push("/payment")
          }
        })
        .catch(() => {
          setFormError(
            "We're having issues joining your union. Please try again"
          )
          setIsLoading(false)
          setScrollTrigger(value => value + 1)
        })
    } else {
      analytics.track("union-unable-to-be-confirmed", {
        plugins: {
          fbpixel: false
        }
      })
      if (person.person_id && person.people_id) {
        sendJoinEvent(
          person.person_id,
          person.people_id,
          getConstant("joinCoverageUnableToConfirmEvent")
        )
      }
      push("/payment")
    }
  }
  return (
    <form id="union" onSubmit={handleSubmit(onSubmit)}>
      <RunIdleTimer resetPersonalDetails={resetPersonalDetails} />
      <Layout
        heading="Your Union"
        sectionStep={2}
        person={person}
        setPerson={setPerson}
        scrollTrigger={scrollTrigger}
      >
        {topError && (
          <Cell span={[4, 8, 12]}>
            <Notification
              overrides={{ Body: { style: { width: "auto" } } }}
              kind="negative"
            >
              {topError}
            </Notification>
          </Cell>
        )}
        <UnionSelector
          control={control}
          deepestIndustry={deepestIndustry}
          error={errors.branch_id}
          isLoading={isLoading}
          setPerson={setPerson}
          person={person}
          value={watch("branch_id")}
          resetPersonalDetails={resetPersonalDetails}
        />
      </Layout>
    </form>
  )
}
