import React from "react"
import { Layout } from "../components/Layout"
import { FormControl } from "../components/FormControl"
import { Input } from "../components/Input"
import { ALIGNMENT, Cell, Grid } from "baseui/layout-grid"
import { Button } from "../components/Button"
import { shareJoinForm } from "../api"
import { useFieldArray, useForm } from "react-hook-form"
import { Person } from "../App"
import { Heading } from "baseui/heading"
import { ParagraphMedium } from "baseui/typography"
import { Notification } from "baseui/notification"
import { useHistory } from "react-router-dom"
import { getConstant } from "../constants"
import { RunIdleTimer } from "../components/RunIdleTimer"

interface ReferralFormProps {
  person: Person
  resetPersonalDetails: (completeReset?: boolean) => void
}

export interface FormValues {
  recipients: Array<{ emailAddress: string; mobileNumber: string }>
}

export const ShareForm = ({
  person,
  resetPersonalDetails
}: ReferralFormProps) => {
  const { register, control, handleSubmit, errors, getValues } = useForm<
    FormValues
  >({
    defaultValues: {
      recipients: [{ emailAddress: "", mobileNumber: "" }]
    }
  })
  const [formError, setFormError] = React.useState("")
  const [isLoading, setIsLoading] = React.useState(false)
  const topError =
    formError ||
    (Object.keys(errors).length > 0 ? "Please review the errors below" : "")
  const { fields, append } = useFieldArray({
    control,
    name: "recipients"
  })
  const { push } = useHistory()

  const onSubmit = (values: FormValues) => {
    setIsLoading(true)
    shareJoinForm(person.person_id, person.people_id, values.recipients)
      .then((response: any) => {
        setIsLoading(false)
        push("/share-complete")
      })

      .catch(() => {
        setFormError(
          "We're having issues sharing the form, please check your details below and try again later"
        )
        setIsLoading(false)
      })
  }

  const changeFunction = (
    event: React.FormEvent<HTMLInputElement>,
    index: number
  ) => {
    const maxShareFormFields = getConstant("maxShareFormFields")
    const mobileKey = `recipients[${fields.length - 1}].mobileNumber`
    const emailKey = `recipients[${fields.length - 1}].emailAddress`

    // if the count of email/mobile fields is less than maxFields, and the last row is not empty
    // append additional inputs
    if (
      fields.length < maxShareFormFields &&
      (getValues(mobileKey) !== "" || getValues(emailKey) !== "")
    ) {
      append({})
    }
  }

  return (
    <form id="share-form" onSubmit={handleSubmit(onSubmit)}>
      <RunIdleTimer resetPersonalDetails={resetPersonalDetails} />
      <Layout
        heading="Every member counts – share and help grow the movement"
        person={person}
        sectionStep={5}
        disableBackwardNavigation={true}
      >
        {topError && (
          <Cell span={[4, 8, 12]}>
            <Notification
              overrides={{ Body: { style: { width: "auto" } } }}
              kind="negative"
            >
              {topError}
            </Notification>
          </Cell>
        )}
        <Cell span={12}>
          <ParagraphMedium>
            Welcome to the union movement! You’ve just made the most powerful
            and valuable decision of your working life.
          </ParagraphMedium>
          <ParagraphMedium>
            The bigger our movement, the louder and stronger our collective
            voice is to drive powerful, positive change. If every new member
            recruits another member, we’ll be able to win better pay, stronger
            conditions and respect at work for everyone.
          </ParagraphMedium>
          <ParagraphMedium>
            Share the great news of your new membership with a workmate, friend
            or family member and encourage them to join their union too.
          </ParagraphMedium>
          <ParagraphMedium>
            Use the tool below to send a text and/or email to someone you know.*
          </ParagraphMedium>
          <ParagraphMedium>
            <details>
              <summary>Preview</summary>
              <Grid>
                <Cell span={6}>
                  <img
                    src={`${getConstant("publicURL")}/images/share-sms.png`}
                    alt="share-sms-preview"
                  />
                </Cell>
                <Cell span={6}>
                  <img
                    src={`${getConstant("publicURL")}/images/share-email.png`}
                    alt="share-email-preview"
                  />
                </Cell>
              </Grid>
            </details>
          </ParagraphMedium>
          <ParagraphMedium>
            <em>
              *Any contact information you provide here will only be used for
              the purposes of this sharing tool, and will not be stored or used
              for any other purpose by Australian Unions.
            </em>
          </ParagraphMedium>
        </Cell>

        {fields.map((field, index) => {
          let last = fields.length - 1
          return (
            <Grid
              key={field.id}
              align={ALIGNMENT.center}
              overrides={
                index === last
                  ? {}
                  : {
                      Grid: {
                        style: {
                          "border-bottom": "2px solid lightgrey"
                        }
                      }
                    }
              }
            >
              <Cell span={[4, 3, 5]}>
                <FormControl
                  key={field.id}
                  error={errors?.recipients?.[index]?.mobileNumber?.message}
                  label="Mobile Number"
                >
                  <Input
                    onChange={event => changeFunction(event, index)}
                    inputRef={register({
                      pattern: {
                        value: /^\+?[\d\s]+$/,
                        message: "Mobile number must only contain digits"
                      },
                      minLength: {
                        value: 10,
                        message:
                          "Mobile number must have at least 10 digits (include area code)"
                      }
                    })}
                    overrides={{
                      InputContainer: { style: { width: "250px" } },
                      Input: {
                        props: {
                          maxLength: "12"
                        }
                      }
                    }}
                    key={field.id}
                    error={!!errors?.recipients?.[index]?.mobileNumber}
                    id={`recipients[${index}].mobileNumber`}
                    name={`recipients[${index}].mobileNumber`}
                    type="tel"
                  />
                </FormControl>
              </Cell>
              <Cell span={[4, 2, 2]}>
                <Heading
                  overrides={{
                    Block: {
                      style: ({ $theme }) => ({
                        ...$theme.typography.HeadingXLarge,
                        textAlign: "center"
                      })
                    }
                  }}
                >
                  or
                </Heading>
              </Cell>
              <Cell span={[4, 3, 5]}>
                <FormControl
                  key={field.id}
                  error={errors?.recipients?.[index]?.emailAddress?.message}
                  label="Email Address"
                >
                  <Input
                    onChange={event => changeFunction(event, index)}
                    inputRef={register({
                      pattern: {
                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                        message: "You must fill out a valid email address"
                      }
                    })}
                    overrides={{
                      InputContainer: { style: { width: "250px" } }
                    }}
                    key={field.id}
                    error={!!errors?.recipients?.[index]?.emailAddress}
                    id={`recipients[${index}].emailAddress`}
                    name={`recipients[${index}].emailAddress`}
                    type="email"
                  />
                </FormControl>
              </Cell>
            </Grid>
          )
        })}

        <Cell span={[12]}>
          <Button isLoading={isLoading}>Share the form</Button>
        </Cell>
      </Layout>
    </form>
  )
}
