import React from "react"
import { useStyletron } from "baseui"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { ParagraphLarge } from "baseui/typography"
import { palette } from "../configurables"
import { Link } from "react-router-dom"

interface ProgressProps {
  label: string
  status: "completed" | "in_progress" | "incomplete"
  url: string
  hideLabelOnSmallScreens: boolean
}
export function Progress({
  label,
  url,
  status,
  hideLabelOnSmallScreens
}: ProgressProps) {
  const [css] = useStyletron()
  let background: string = ""
  switch (status) {
    case "in_progress":
      background = "#e8e8e8"
      break
    case "incomplete":
      background = "white"
      break
    case "completed":
      background = palette.primary
      break
  }
  const progress = (
    <div
      className={css({
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center",
        width: "55px",
        ...(hideLabelOnSmallScreens
          ? { "@media screen and (max-width: 480px)": { width: "32px" } }
          : {})
      })}
    >
      <div
        className={css({
          backgroundColor: background,
          height: "42px",
          width: "42px",
          ...(hideLabelOnSmallScreens
            ? {
                "@media screen and (max-width: 480px)": {
                  height: "32px",
                  width: "32px"
                }
              }
            : {}),
          boxSizing: "border-box",
          border: status === "completed" ? "none" : "solid 2px black",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        })}
      >
        {status === "completed" && (
          <FontAwesomeIcon
            icon={faCheck}
            className={css({
              color: "white",
              height: "20px",
              width: "20px",
              fontSize: "20px"
            })}
          />
        )}
      </div>
      <ParagraphLarge
        overrides={{
          Block: {
            style: {
              ...(hideLabelOnSmallScreens
                ? {
                    "@media screen and (max-width: 480px)": { display: "none" }
                  }
                : {}),
              maxWidth: "100px",
              fontSize: "12px",
              lineHeight: "1.5em"
            }
          }
        }}
      >
        {label}
      </ParagraphLarge>
    </div>
  )

  if (url && status === "completed") {
    return (
      <Link className={css({ textDecoration: "none" })} to={url}>
        {progress}
      </Link>
    )
  }
  return progress
}

interface ProgressBarProps {
  sections: {
    label: string
    url: string
  }[]
  sectionStep: number
  disableBackwardNavigation?: boolean
}
export function ProgressBar({
  sections,
  sectionStep,
  disableBackwardNavigation
}: ProgressBarProps) {
  const smallOnMobile = sections.length >= 5
  const [css] = useStyletron()
  const maxWidth = `${sections.length * 150}px`
  return (
    <div className={css({ maxWidth })}>
      <div
        className={css({
          boxSizing: "border-box",
          height: "1px",
          borderBottom: "2px dashed #000000",
          margin: "3em 1.5em 0",
          ...(smallOnMobile
            ? {
                "@media screen and (max-width: 480px)": {
                  margin: "2.5em 1.5em 0px;"
                }
              }
            : {})
        })}
      />
      <div
        className={css({
          marginTop: "-23px",
          ...(smallOnMobile
            ? { "@media screen and (max-width: 480px)": { marginTop: "-18px" } }
            : {}),
          display: "flex",
          justifyContent: "space-between"
        })}
      >
        {sections.map(({ label, url }, index) => {
          let status: ProgressProps["status"]
          if (index < sectionStep) {
            status = "completed"
          } else if (index === sectionStep) {
            status = "in_progress"
          } else {
            status = "incomplete"
          }
          return (
            <Progress
              key={label}
              label={label}
              url={disableBackwardNavigation ? "" : url}
              hideLabelOnSmallScreens={smallOnMobile}
              status={status}
            />
          )
        })}
      </div>
    </div>
  )
}
