import React from "react"
import { Layout } from "../components/Layout"
import { Cell } from "baseui/layout-grid"
import { ParagraphMedium } from "baseui/typography"
import { Person } from "../App"
import { findBranch, getDollarsAndCents } from "../util"
import { getConstant } from "../constants"
import { Button } from "../components/Button"
import { useHistory } from "react-router-dom"
import { RunIdleTimer } from "../components/RunIdleTimer"
import { Heading } from "baseui/heading"
import { useStyletron } from "baseui"
import { StyleObject } from "styletron-react"

const ContactParagraph = () => (
  <ParagraphMedium>
    If you have any questions about the join process, you can contact the
    Australian Unions Support Centre (Monday-Friday 9am-7pm Melbourne) on{" "}
    <a href="tel:1300 486 466">1300 486 466</a>, or by email -{" "}
    <a href="mailto:help@actu.org.au">help@actu.org.au</a>
  </ParagraphMedium>
)
const ContactStatusParagraph = () => (
  <ParagraphMedium>
    You can check on progress by contacting the Australian Unions Support Centre
    (Monday-Friday 9am-7pm Melbourne) on{" "}
    <a href="tel:1300 486 466">1300 486 466</a>, or by email -{" "}
    <a href="mailto:help@actu.org.au">help@actu.org.au</a>
  </ParagraphMedium>
)

const ReferenceParagraph = ({ reference }: { reference: string }) => (
  <ParagraphMedium>
    Your union join reference number* is: {reference}
  </ParagraphMedium>
)
const NotMemberNumberParagraph = ({ union }: { union?: string }) => (
  <ParagraphMedium>
    <em>
      *Please note, this is not your member number.
      {union ? ` Your member number will be provided by the ${union}.` : ""}
    </em>
  </ParagraphMedium>
)
const SummaryParagraph = () => (
  <ParagraphMedium>
    A summary of this message has been emailed to you.
  </ParagraphMedium>
)

const JoinParagraph = ({ union }: { union?: string }) => (
  <ParagraphMedium>
    {union
      ? `Congratulations on your decision to join the ${union}!`
      : `Congratulations on taking the first step to joining your union.`}{" "}
    By joining, you’ll be driving positive change in your workplace. That means
    better wages, the workplace conditions you deserve and support standing by
    if anything goes wrong.
  </ParagraphMedium>
)

const ShareParagraph = ({ css }: { css: (arg: StyleObject) => string }) => (
  <>
    <Heading
      overrides={{
        Block: {
          style: ({ $theme }) => ({
            ...$theme.typography.HeadingXLarge,
            textAlign: "left",
            marginBottom: 0
          })
        }
      }}
    >
      Every member counts – share and help grow the movement.
    </Heading>
    <hr
      className={css({
        border: 0,
        height: "2px",
        backgroundColor: "#333333"
      })}
    />
    <ParagraphMedium>
      The bigger our movement, the louder and stronger our collective voice is
      to drive powerful, positive change.
    </ParagraphMedium>
    <ParagraphMedium>
      If every new member recruits another member, we’ll be able to win better
      pay, stronger conditions and respect at work for everyone.
    </ParagraphMedium>
    <ParagraphMedium>
      Share the great news of your new membership with a workmate, friend or
      family member and encourage them to join their union too.
    </ParagraphMedium>
  </>
)
const XmasParagraph = () => (
  <>
    <ParagraphMedium>
      Please note that the Australian Unions Support Centre will be closed
      between Friday 22 December and Tuesday 2 January. Most of our affiliated
      unions will also be closed or operating with reduced staffing during this
      period.
    </ParagraphMedium>
    <ParagraphMedium>
      As a result, it may take a little longer than usual to process your
      application. Please allow 7 - 10 days before contacting the union to
      enquire about your application. We wish you a safe and happy festive
      season.
    </ParagraphMedium>
    <ParagraphMedium>
      You can contact the Australian Unions Support Centre (Monday-Friday
      9am-6:30pm Melbourne) on <a href="tel:1300 486 466">1300 486 466</a>, or
      by email - <a href="mailto:help@actu.org.au">help@actu.org.au</a> - from
      9am AEDT, Wednesday 3 January 2023.
    </ParagraphMedium>
  </>
)

export const CompletePage = ({
  person,
  setPerson,
  resetPersonalDetails,
  volunteerOnly
}: {
  person: Person
  resetPersonalDetails: (completeReset?: boolean) => void
  setPerson: (person: Person | ((oldPerson: Person) => Person)) => void
  volunteerOnly: boolean
}) => {
  const [css] = useStyletron()
  const { push } = useHistory()
  const branch = findBranch(person)
  let step = 3
  if (person.join) {
    if (branch && branch.payment_mode === "bypass") {
      step = step + 1
    } else {
      step = step + 2
    }
  }
  if (person.volunteer && person.join) {
    step++
  }
  let completeMessage: JSX.Element | null = null
  const { dollars, cents } = getDollarsAndCents(person.cost)
  const reference = person.person_id || ""
  if (!volunteerOnly) {
    if (branch) {
      if (branch.payment_mode === "bypass") {
        // Scenario A on the JIRA ticket (RG-149)
        completeMessage = (
          <>
            <JoinParagraph />
            <ParagraphMedium>
              To join {branch.short_name} please complete the{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={branch.website}
              >
                membership application form on their website
              </a>
              .
            </ParagraphMedium>
            <ContactParagraph />
            <ReferenceParagraph reference={reference} />
            <SummaryParagraph />
            <NotMemberNumberParagraph />
            <ShareParagraph css={css} />
          </>
        )
      } else if (branch.payment_mode === "stored") {
        // Scenario D on the JIRA ticket (RG-149)
        completeMessage = (
          <>
            <JoinParagraph union={branch.short_name} />
            {person.payment_type === "dd" ? (
              <>
                <ParagraphMedium>
                  Your membership application has been sent to the{" "}
                  {branch.short_name} and they will be in touch soon to confirm
                  it has been processed and you've been joined up.
                </ParagraphMedium>
                <ParagraphMedium>
                  Your first payment of ${dollars}.{cents}* will be processed by
                  your union via Direct Debit for the first month of your
                  membership. After this the {branch.short_name} will move you
                  onto their fee rate. Your new union will confirm your
                  membership number and ongoing fee rate – please note, you are
                  not officially a member until you receive this confirmation
                  directly from your union.
                </ParagraphMedium>
                <ParagraphMedium>
                  A copy of your Direct Debit Service Agreement with the{" "}
                  {branch.short_name} has been emailed to you for your records.
                </ParagraphMedium>
              </>
            ) : (
              <ParagraphMedium>
                Your membership application has been sent to the{" "}
                {branch.short_name} and they will be in touch soon to collect
                your payment details and complete your membership application.
              </ParagraphMedium>
            )}
            <ContactParagraph />
            <ReferenceParagraph reference={reference} />
            <SummaryParagraph />
            {person.payment_type === "dd" && (
              <ParagraphMedium>
                <em>
                  *In some cases, branches may apply the applicable ongoing rate
                  for the first billing period.
                </em>
              </ParagraphMedium>
            )}
            <NotMemberNumberParagraph union={branch.short_name} />
            <ShareParagraph css={css} />
          </>
        )
      } else if (branch.payment_mode === "fatzebra") {
        // Scenario C on the JIRA ticket (RG-149)
        completeMessage = (
          <>
            <JoinParagraph union={branch.short_name} />
            <ParagraphMedium>
              Your membership application has been sent to the{" "}
              {branch.short_name} and they will be in touch soon to confirm it
              has been processed and you've been joined up.
            </ParagraphMedium>
            <ParagraphMedium>
              Your first payment of ${dollars}.{cents} has been{" "}
              {person.payment_type === "cc" ? "processed" : "scheduled"} via{" "}
              {person.payment_type === "cc" ? "credit card" : "direct debit"}{" "}
              for the first month of your membership. After this, the{" "}
              {branch.short_name} will move you onto their fee rate. Your new
              union will confirm your membership number and ongoing fee rate –
              please note, you are not officially a member until you receive
              this confirmation directly from your union.
            </ParagraphMedium>
            {person.payment_type === "dd" && (
              <ParagraphMedium>
                A copy of your Direct Debit Service Agreement with the{" "}
                {branch.short_name} has been emailed to you for your records.
              </ParagraphMedium>
            )}
            <ContactParagraph />
            <ReferenceParagraph reference={reference} />
            <SummaryParagraph />
            <NotMemberNumberParagraph union={branch.short_name} />
            <ShareParagraph css={css} />
          </>
        )
      }
    } else if (getConstant("xmasBreakEnabled")) {
      // Only applies in xmas break when env var is turned on
      completeMessage = (
        <>
          <JoinParagraph />
          <XmasParagraph />
          {person.payment_type === "dd" && (
            <ParagraphMedium>
              A copy of your Direct Debit Service Agreement has been emailed to
              you for your records. An updated Direct Debit Service Agreement
              will be sent to you once your union is confirmed.
            </ParagraphMedium>
          )}
          <ReferenceParagraph reference={reference} />
          <SummaryParagraph />
          <NotMemberNumberParagraph />
          <ShareParagraph css={css} />
        </>
      )
    } else if (person.netsuite_branch_id !== undefined) {
      /* The latter condition is currently what's doing the work, we should remove it once the payment mode is being set correctly on AWU branches */
      completeMessage = (
        <>
          <JoinParagraph union="AWU" />

          <ReferenceParagraph reference={reference} />
          <SummaryParagraph />
          <NotMemberNumberParagraph union="AWU" />
          <ShareParagraph css={css} />
        </>
      )
    } else {
      // Scenario B on the JIRA ticket (RG-149)
      // Manual confirm
      completeMessage = (
        <>
          <JoinParagraph />
          <ParagraphMedium>
            We'll confirm your union with you shortly and send them your
            details. Please note, payment will only be taken at that stage.
          </ParagraphMedium>
          {person.payment_type === "dd" && (
            <ParagraphMedium>
              A copy of your Direct Debit Service Agreement has been emailed to
              you for your records. An updated Direct Debit Service Agreement
              will be sent to you once your union is confirmed.
            </ParagraphMedium>
          )}
          <ContactStatusParagraph />
          <ReferenceParagraph reference={reference} />
          <SummaryParagraph />
          <NotMemberNumberParagraph />
          <ShareParagraph css={css} />
        </>
      )
    }
  } else {
    completeMessage = (
      <>
        <ParagraphMedium>
          {volunteerOnly
            ? "Thanks for signing up."
            : "Welcome to Australian Unions"}
        </ParagraphMedium>
        {volunteerOnly ? (
          <ParagraphMedium>
            We'll be in touch soon with ways you can volunteer. In the meantime,{" "}
            <a href="https://www.australianunions.org.au/campaigns">
              check out the campaigns we are currently running.
            </a>
          </ParagraphMedium>
        ) : (
          <ParagraphMedium>
            We'll be in touch shortly to complete the joining process.
          </ParagraphMedium>
        )}
        <ParagraphMedium>
          Please also consider making a{" "}
          <a href="https://www.australianunions.org.au/donate">donation</a> to
          help make the union movement stronger.
        </ParagraphMedium>
      </>
    )
  }
  const onSubmit = () => {
    push("/share")
  }

  return (
    <form id="complete-page" onSubmit={onSubmit}>
      <RunIdleTimer resetPersonalDetails={resetPersonalDetails} />
      <Layout
        heading="Thanks for joining the movement."
        sectionStep={step}
        disableBackwardNavigation={true}
        person={person}
        setPerson={setPerson}
      >
        <Cell span={[4, 8, 12]}>{completeMessage}</Cell>
        <Cell span={[12]}>
          <Button>Share the form</Button>
        </Cell>
      </Layout>
    </form>
  )
}
