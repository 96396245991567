import {
  ValueOrNullOrUndefined,
  Industry,
  Coverage
} from "./pages/EmploymentDetailsForm"
import { Person } from "./App"
import { getConstant, AutoconfirmConfigs } from "./constants"

export type State =
  | "NT"
  | "ACT"
  | "NSW"
  | "VIC"
  | "QLD"
  | "SA"
  | "WA"
  | "TAS"
  | "Other"
  | null
export function getStateByPostcode(postcode: string | undefined): State {
  if (!postcode) {
    return null
  }
  if (postcode.length === 3) {
    postcode = `0${postcode}`
  }
  const firstDigit = (postcode && postcode[0]) || "NaN"
  const code = Number(postcode)
  switch (firstDigit) {
    case "0":
      // 	08** and 09**
      if (code >= 800 && code <= 999) {
        return "NT"
      } else if (code >= 200 && code <= 299) {
        return "ACT"
      }
      break
    case "2":
      // 2600 to 2618 and 29**
      if ((code >= 2600 && code <= 2618) || (code >= 2900 && code <= 2920)) {
        return "ACT"
      } else {
        return "NSW"
      }
    case "8":
    case "3":
      return "VIC"
    case "4":
    case "9":
      return "QLD"
    case "5":
      return "SA"
    case "6":
      if (code === 6798) {
        // Christmas Island
        return "Other"
      } else {
        return "WA"
      }
    case "7":
      return "TAS"
  }

  return null
}

export function getQueryVariable(targetVar: string, query: string) {
  if (query[0] === "?") {
    query = query.substring(1)
  }
  const vars = query.split("&")
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=")
    if (decodeURIComponent(pair[0]) === targetVar) {
      return decodeURIComponent(pair[1])
    }
  }
}

export function isAutoconfirmState(state: State | undefined) {
  if (state) {
    // Need to cast the type here because typescript will not infer the stricter type
    // in a template stirng, instead it just defaults as a string
    return getConstant(`autoconfirmEnabledFor${state}` as AutoconfirmConfigs)
  }
  return false
}

export function createFullName(
  firstname: string | null,
  lastname: string | null
) {
  if (firstname && lastname) {
    return [firstname, lastname].join(" ")
  } else if (firstname) {
    return firstname
  } else if (lastname) {
    return lastname
  }
  return ""
}
export function getDeepestIndustry(
  industry_1: ValueOrNullOrUndefined,
  industry_2: ValueOrNullOrUndefined,
  industry_3: ValueOrNullOrUndefined,
  industry_4: ValueOrNullOrUndefined,
  job_search:
    | {
        value: ValueOrNullOrUndefined
      }
    | null
    | undefined
): Industry | null {
  if (job_search) {
    if (job_search.value && job_search.value[0]) {
      return job_search.value[0].industry
    }
  } else {
    const deepestIndustry = industry_4 || industry_3 || industry_2 || industry_1
    if (deepestIndustry) {
      return deepestIndustry[0].industry
    }
  }
  return null
}

export function getBranch(
  deepestIndustry: Industry | null,
  branch_id: string | undefined | null
): Coverage | null {
  const coverage = deepestIndustry && deepestIndustry.coverage
  let selected_union: Coverage | null = null
  if (coverage) {
    coverage.forEach(union => {
      if (union.branch_id === branch_id) {
        selected_union = union
      }
    })
  }
  return selected_union
}

export function getSelectedUnionName(
  deepestIndustry: Industry | null,
  branch_id: string | undefined | null
) {
  const union = getBranch(deepestIndustry, branch_id)
  if (union) {
    return union.full_name
  } else {
    return ""
  }
}

export function getBranchId(
  branch_id: string | undefined | null,
  deepestIndustry: Industry | null
) {
  if (branch_id) {
    return branch_id
  } else if (
    deepestIndustry &&
    deepestIndustry.coverage &&
    deepestIndustry.coverage.length === 1 &&
    !deepestIndustry.coverage[0].is_contested
  ) {
    return deepestIndustry.coverage[0].branch_id
  }
  return null
}

export function getBranchName(person: Person) {
  const deepestIndustry = getDeepestIndustry(
    person.industry_1,
    person.industry_2,
    person.industry_3,
    person.industry_4,
    person.job_search
  )
  const branch_id = getBranchId(person.branch_id, deepestIndustry)
  const unionName = getSelectedUnionName(deepestIndustry, branch_id)
  return unionName
}

export function findBranch(person: Person) {
  const deepestIndustry = getDeepestIndustry(
    person.industry_1,
    person.industry_2,
    person.industry_3,
    person.industry_4,
    person.job_search
  )
  const branch_id = getBranchId(person.branch_id, deepestIndustry)
  const branch = getBranch(deepestIndustry, branch_id)
  return branch
}
// https://gist.github.com/atesgoral/141616
function leftPad(n: string | number, len: number = 2) {
  return new Array(len - String(n).length + 1).join("0").concat(n.toString())
}

export function getDollarsAndCents(cost: number | undefined) {
  const dollars = Math.floor((cost || 0) / 100)
  const cents = leftPad((cost || 0) % 100, 2)
  return { dollars, cents }
}

export function shuffleArray(array: Array<any>) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
}
